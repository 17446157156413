class FormRepeater {
    constructor(domElement = null, {
        collectionClass = null,
        addButtonClass = null,
        removeButtonClass = null,
        onAdd = null,
        onRemove = null,
        removeAction = true,
        removeAll = false,
    }) {
        this.collectionContainer = $(domElement)
        if(!this.collectionContainer.length) {
            console.error('Please provide propper DOM element for FormRepeater class')
            return
        }

        const {
            prototype,
            labelAddBlock,
            labelRemoveBlock,
            repeaterMin,
            repeaterMax,
        } = this.collectionContainer.data()

        this.prototype = prototype
        this.labelAddBlock = labelAddBlock
        this.labelRemoveBlock = labelRemoveBlock

        this.repeaterMax = repeaterMax
        this.repeaterMin = repeaterMin

        this.collectionClass = collectionClass || '.formbuilder-container-block'
        this.addButtonClass = `${addButtonClass} add-collection-button`
        this.removeButtonClass = `${removeButtonClass} remove-collection-button`

        this.onAdd = null
        if(onAdd && typeof onAdd === 'function') {
            this.onAdd = onAdd
        }

        this.onRemove = null
        if(onRemove && typeof onRemove === 'function') {
            this.onRemove = onRemove
        }

        this.removeAction = removeAction
        this.removeAll = removeAll

        this.collectionIndex = null

        this.init()
    }

    destroy() {
        this.removeEventListeners()
    }

    init() {
        this.setupContainer()
        this.addEventListeners()
    }

    addEventListeners() {
        this.collectionContainer.on('click touch', '.add-collection-button', this.handleAddCollection.bind(this))
        this.collectionContainer.on('click touch', '.remove-collection-button', this.handleRemoveCollection.bind(this))
    }

    removeEventListeners() {
        this.collectionContainer.off('click touch')
    }

    handleAddCollection(event) {
        event.preventDefault()

        const $button = $(event.currentTarget)

        this.collectionIndex = this.collectionIndex + 1
        const newMarkup = this.prototype.replace(/__name__/g, this.collectionIndex)

        const $newCollection = $(newMarkup)

        $newCollection.insertBefore($button)
        this.appendRemoveButton($newCollection)

        this.verifyButtons()

        // Trigger callback
        if(this.onAdd) {
            this.onAdd()
        }
    }

    handleRemoveCollection(event) {
        event.preventDefault()

        // Find & remove node
        const $button = $(event.currentTarget)
        const collection = $button.closest(this.collectionClass)

        if(collection.length) {
            collection.remove()
        }

        this.verifyButtons()

        // Trigger callback
        if(this.onRemove) {
            this.onRemove()
        }
    }

    setupContainer() {
        const $collections = this.collectionContainer.find(this.collectionClass)
        this.collectionIndex = $collections.length - 1

        $collections.each((index, item) => {
            if(index > 0) {
                this.appendRemoveButton(item)
            }
        })

        this.appendAddButton()
        this.verifyButtons()
    }

    appendAddButton() {
        this.collectionContainer.append(
            `
                <a href="javascript:;" class="${this.addButtonClass}" title="Add">
                    ${this.labelAddBlock}
                </a>
            `
        )
    }

    appendRemoveButton(collectionItem = null) {
        if (this.removeAction) {
            const $form = $(collectionItem)

            $form.append(
                `
                <a href="javascript:;" class="${this.removeButtonClass}" title="Remove">
                    ${this.labelRemoveBlock}
                </a>
                `
            )
        }
    }

    verifyButtons() {
        const $addButton = this.collectionContainer.find('.add-collection-button')
        const collectionLenght = this.collectionContainer.find(this.collectionClass).length

        if (collectionLenght < this.repeaterMax) {
            $addButton.removeClass('d-none')
        } else {
            $addButton.addClass('d-none')
        }
    }
}

export default FormRepeater
