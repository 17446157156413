import Forms from '../..'

class NDC extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.addEventListeners()
        //this.setDrawerTrigger()
    }

    addEventListeners() {
        // Request type selection
        this.form.on('change', '.request-type-select', event => {
            const selectedValue = this.form.find('.request-type-select:checked').val()

            const $iataFieldContainer = this.form.find('.iata-type-field-container')
            const { showField } = $iataFieldContainer.data()

            if (selectedValue === showField) {
                $iataFieldContainer.removeClass('d-none')
            } else {
                $iataFieldContainer.addClass('d-none')
            }
        })
    }

    /**
     * Hide button trigger (By IP)
     */
    setDrawerTrigger() {
        const { hideTrigger } = this.form.data()
        if (hideTrigger) {
            const offCanvas = this.form.closest('.offcanvas')
            const drawerId = offCanvas.attr('id')

            const triggerButton = $(`a[href="#${drawerId}"]`)
            if (triggerButton.length) {
                triggerButton.addClass('d-none')
                triggerButton.closest('.button-container').addClass('d-none')
            }
        }
    }
}

export default NDC
