/**
 * AreaBrickBundle main script file
 */


/**
 * Components
 */
import ButtonBrick from './components/Button'
import RegularDrawerBrick from './components/RegularDrawer'
import TableBrick from './components/Table'
import HeaderTicker from './components/HeaderTicker'


/**
 * Initialization
 */

$('.button-container').each(function() {
    new ButtonBrick(this)
})

$('.table-areabrick').each(function() {
    new TableBrick(this)
})

$('.regular-drawer-container').each(function() {
    new RegularDrawerBrick(this)
})

$('.header-ticker-container').each(function() {
    new HeaderTicker(this)
})
