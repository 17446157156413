import XRegExp from 'xregexp'
import moment from 'moment'
import axios from "axios"
import PhoneNumberInput from '../components/fields/PhoneNumber'
import { initSingleSelect } from '../components/selects'
import TextArea from '../components/fields/TextArea'
import DateInput from '../components/fields/DateInput'
import TimeRangeInput from '../components/fields/TimeRangeInput'
import FileUploadInput from './fields/FileUploadInput'
import PassengerInput from './fields/PassengerInput'
import CreditCardExpiryInput from './fields/CreditCardExpiryInput'

import { updateUrlParameter } from 'ContentBundle/js/frontend/utils/qS'
import { isElementVisible } from 'ContentBundle/js/frontend/utils/domHelpers'

class Forms {
    constructor(domElement = null) {
        this.form = $(domElement)
        this.formId = this.form.attr('id')

        if(!this.form.length) {
            return
        }

        this.step = 1

        this.selects = []
        this.textareas = []
        this.dateInputs = []
        this.timeRangeInputs = []
        this.dropzoneInputs = {}
        this.passengersInputs = []
        this.phoneNumberInputs = []
        this.creditCardExpiryInputs = []

        this.stepInput = null

        const {
            country,
            language,
            displayRegions,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.localizedRegions = Object.keys(displayRegions).reduce((acc, k) => {
            acc[k.toLowerCase()] = displayRegions[k]
            return acc
        }, {})

        this.formEndPoints = null
        if (this.form.hasClass('formbuilder') && this.form.data('ajaxStructureUrl')) {
            this.setEndpoints(this.form.data('ajaxStructureUrl'))
        } else {
            this.init()
        }

        window.formBuilderForms = window.formBuilderForms || {}
        window.formBuilderForms[this.formId] = this
    }

    /**
     * Fetch endpoints for form, eg. file-chunk, file-add etc.
     * @param {String} ajaxStructureUrl
     */
    async setEndpoints(ajaxStructureUrl = '') {
        try {
            const requestUri = updateUrlParameter(ajaxStructureUrl, 'ajax', true)
            const response = await axios.get(requestUri)
            const { data } = response

            this.formEndPoints = data

            this.init()
        } catch(err) {
            console.error('Error fetching form endpoints')
            throw err
        }
    }

    init() {
        this.initFields()

        /**
         * Reset form
         */
        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            if(this.form.hasClass('no-clear-on-close')) {
                return
            }

            this.step = 1

            this.form.removeClass('d-none')

            this.form.find('.alert').remove()
            this.form.closest('.offcanvas-body').find('.template-content').remove()

            // Tabs reset
            this.form.find('.tab-pane').removeClass('active show')
            this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')

            // Fields
            this.form.find('.visible-fields input:not([type="checkbox"]):not([type="radio"]):not([type="hidden"]), .visible-fields textarea').each(function() {
                const field = $(this)
                const { defaultValue } = field.data()

                const value = defaultValue || ''
                field.val(value).trigger('change')
            })

            this.form.find('.visible-fields input[type="radio"], .visible-fields input[type="checkbox"]').each(function() {
                const field = $(this)
                const { defaultValue } = field.data()
                const value = field.attr('value')

                const isChecked = defaultValue
                    ? value == defaultValue
                    : false

                field.prop('checked', isChecked).trigger('change')
            })

            // Invalid feedbacks
            this.form.find('.is-invalid').removeClass('is-invalid')
            this.form.find('.invalid-feedback').remove()

            this.initFields(true)
        })

        this.form.on('click', 'button[type="submit"]', (event) => {
            $('input[type="submit"]', $(event.currentTarget).parents('form')).removeAttr('clicked');
            $(event.currentTarget).attr('clicked', 'true');
        })

        this.form.on('submit', async (event) => {
            event.preventDefault()
            event.stopPropagation()

            const submitButton = this.form.find('button[type="submit"][clicked=true]')

            // Disable multiple submission
            if (submitButton.hasClass('requesting')) {
                return
            }

            const formData = this.getFormData(this.form[0])
            submitButton.addClass('requesting')

            try {
                // Append "ajax=true" as query param
                let requestUri = this.form.attr('action')
                requestUri = updateUrlParameter(requestUri, 'ajax', true)
                requestUri = updateUrlParameter(requestUri, 'step', this.step)

                const response = await axios.post(requestUri, formData)

                submitButton.removeClass('requesting')

                this.clearErrorResponse()
                this.updateForm(response.data)

                if (submitButton.hasClass('next')) {
                    if (this.validateStep()) {
                        this.toNextStep()
                    }
                }

                this.afterSubmit()
            } catch (error) {
                submitButton.removeClass('requesting')

                if (error && error.response && error.response.data) {
                    this.handleErrorResponse(error.response.data)
                }

                console.log(error)
                this.afterSubmit()

                throw error
            }
        })

        // New request action
        this.form.on('click touch', '.btn-new-request', () => this.reOpenForm())

        // Used for detecting pasting to field
        let ctrlDown = false
        let ctrlKey = 17
        let cmdKey = 91
        let vKey = 86

        this.form.on('keydown', '.allow-paste', event => {
            if (event.keyCode === ctrlKey || event.keyCode === cmdKey) ctrlDown = true
        })

        this.form.on('keyup', '.allow-paste', event => {
            if (event.keyCode === ctrlKey || event.keyCode === cmdKey) ctrlDown = false
        })

        /**
         * Special inputs case
         */

        // Disable paste-ing to input (eg. repeat e-mail & password)
        this.form.on('paste', '.disable-paste', event => {
            const $input = $(event.currentTarget)

            const clipBoard = event.originalEvent.clipboardData || window.clipboardData
            const pasteContent = clipBoard.getData("text")

            if ($input.hasClass('numbers-only')) {
                // Check if input can have numbers => allow paste
                const regex = XRegExp("^[0-9]*$")
                if (!regex.test(pasteContent)) {
                    event.preventDefault()
                }
            } else {
                event.preventDefault()
            }
        })

        // Letters only
        this.form.on('keydown', '.letters-only', event => {
            const $input = $(event.currentTarget)

            // Space code
            if($input.hasClass('with-space')) {
                if (event.keyCode === 32) {
                    return true
                }
            }

            // Dash
            if($input.hasClass('with-dash')) {
                // Keyboard + keypad
                if (event.keyCode === 189 || event.keyCode === 109) {
                    return true
                }
            }

            const unicodeWordRegex = XRegExp("^\\p{L}")
            return unicodeWordRegex.test(event.key)
        })

        // Numbers only
        this.form.on('keydown', '.numbers-only', event => {
            const $input = $(event.currentTarget)

            // Allow pasting
            if ($input.hasClass('allow-paste')) {
                if (ctrlDown && event.keyCode === vKey) {
                    return true
                }
            }

            // Space code
            if($input.hasClass('with-space')) {
                if (event.keyCode === 32) {
                    return true
                }
            }

            // Dash
            if($input.hasClass('with-dash')) {
                // Keyboard + keypad
                if (event.keyCode === 189 || event.keyCode === 109) {
                    return true
                }
            }

            // Backspace
            if (!$input.hasClass('disable-backspace')) {
                if (event.keyCode === 8) {
                    return true
                }
            }

            const regex = XRegExp("[0-9]+$")
            return regex.test(event.key)
        })

        // Alpha-numeric only!
        this.form.on('keydown', '.alpha-numeric-only', event => {
            const $input = $(event.currentTarget)

            // Space code
            if($input.hasClass('with-space')) {
                if (event.keyCode === 32) {
                    return true
                }
            }

            // Dash
            if($input.hasClass('with-dash')) {
                // Keyboard + keypad
                if (event.keyCode === 189 || event.keyCode === 109) {
                    return true
                }
            }

            const regex = XRegExp("^[a-zA-Z0-9]+$")
            return regex.test(event.key)
        })

        // No special characters
        this.form.on('keydown', '.no-special-characters', event => {
            const $input = $(event.currentTarget)

            // Dash
            if($input.hasClass('with-dash')) {
                // Keyboard + keypad
                if (event.keyCode === 189 || event.keyCode === 109) {
                    return true
                }
            }

            // "Special charachters set"
            const regex = XRegExp(/[~`!@#$%\^&*()+=\-\[\]\\';,\./{}|\\":<>_\?]/g)
            return !regex.test(event.key)
        })

        // No numeric characters (letters + special characters)
        this.form.on('keydown', '.no-numbers', event => {
            const regex = XRegExp(/\d/g)
            return !regex.test(event.key)
        })
    }

    /**
     * Fields initialization
     * @param {Boolean} clear clear action flag
     */
    initFields(clear = false) {
        this.initPhone()
        this.initSelect()
        this.initTextAreas()
        this.initDates()
        this.initTimeRangeInputs()
        this.initCreditCardExpiry()
        this.initPassengersInputs()

        this.reInitDropzone(!clear)

        // Step input
        this.stepInput = this.form.find('.form-input[data-input-type="final_step_checkbox"]')
        this.stepInput = this.stepInput.length ? this.stepInput : null
    }

    initPhone() {
        const phoneFields = this.form.find('.form-input[data-input-type="phone-input"]')
        if (phoneFields.length) {
            this.destroyPhoneNumbers()

            phoneFields.each((_, field) => {
                const inputInstance = new PhoneNumberInput(field, {
                    initialCountry: this.userCountry, // Selected country from header
                    localizedCountries: this.localizedRegions, // Localized regions
                    options: {} // Extra options
                })

                this.phoneNumberInputs.push(inputInstance)
            })
        }
    }

    initSelect(keepValues = false) {
        const selectFields = this.form.find('.form-input[data-input-type="select"]')
        if (selectFields.length) {
            this.destroySelects(keepValues)

            selectFields.each((_, select) => {
                const $select = $(select)
                const inputId = $select.attr('id')
                const inputContainer = $select.parent()
                const inputLabel = $select.next('.form-label')

                initSingleSelect(select, {
                    respect_word_boundaries: false,
                    render: {
                        optgroup_header: function(data, escape) {
                            return `<div class="optgroup-header bordered">${escape(data.label)}<span class="divider"></span></div>`
                        },
                        option: function(data, escape) {
                            return `
                            <div class="option d-flex flex-row align-items-center">
                                ${escape(data.text)}
                            </div>
                            `
                        },
                        item: function(data, escape) {
                            return `<div class="item">${escape(data.text)}</div>`
                        }
                    },
                    // Floating label logic
                    onFocus: () => {
                        inputLabel.addClass('fp-floating-label--focused')
                    },
                    onBlur: () => {
                        inputLabel.removeClass('fp-floating-label--focused')
                    },
                    onItemAdd: () => {
                        inputLabel.addClass('fp-floating-label--valued')
                    },
                    onDelete: () => {
                        inputLabel.removeClass('fp-floating-label--valued')
                    },
                    onInitialize: function() {
                        // Set label
                        const val = this.getValue()
                        const trimmedValue = val.trim()

                        if (trimmedValue) {
                            inputLabel.addClass('fp-floating-label--valued')
                        } else {
                            inputLabel.removeClass('fp-floating-label--valued')
                        }
                    },
                    onChange: val => {
                        if ($(select).attr('name').includes('flight_type')) {
                            if (val == 'layover' || val == 'return') {
                                this.form.find('.range-input').addClass('d-none')
                                this.form.find('.departure-return-inputs').removeClass('d-none')
                            } else {
                                this.form.find('.departure-return-inputs').addClass('d-none')
                                this.form.find('.range-input').removeClass('d-none')
                            }
                        }

                        // Set label
                        const trimmedValue = val.trim()

                        if (trimmedValue) {
                            inputLabel.addClass('fp-floating-label--valued')
                        } else {
                            inputLabel.removeClass('fp-floating-label--valued')
                        }
                    },
                    onClear: () => {
                        inputLabel.removeClass('fp-floating-label--valued')
                    }
                })

                // Fetch selectize instance
                const selectizeInstance = select.selectize

                // Outside click "disable" multiple dropdowns open at same time
                $('html').on(`click.${inputId}`, event => {
                    if (
                        inputContainer.has(event.target).length === 0
                    ) {
                        // Trigger blur method (Remove focus and closes dropdown)
                        selectizeInstance.blur()
                    }
                })

                // Add instance to store
                this.selects.push(selectizeInstance)

                // Allow creation of custom options for input
                if ($select.hasClass('can-create-item')) {
                    selectizeInstance.settings.create = true
                    selectizeInstance.settings.createOnBlur = true
                }
            })
        }
    }

    initTextAreas() {
        const textAreas = this.form.find('textarea.form-input')
        if(textAreas) {
            this.destroyTextareas()
            textAreas.each((_, input) => {
                this.textareas.push(new TextArea(input))
            })
        }
    }

    initDates() {
        const dateFields = this.form.find('.form-input[data-input-type="date"]')
        if (dateFields.length) {
            this.destroyDateInputs()
            dateFields.each((_, dateField) => {
                const $input = $(dateField)
                const value = $input.val()
                const { minDate, maxDate, type } = $input.data()

                const extraOptions = {}
                if(typeof minDate !== 'undefined') {
                    extraOptions['minDate'] = Date.parse(minDate)
                        ? new Date(minDate)
                        : minDate
                }

                if(typeof maxDate !== 'undefined') {
                    extraOptions['maxDate'] = Date.parse(maxDate)
                        ? new Date(maxDate)
                        : maxDate
                }

                const inputInstance = new DateInput(dateField, {
                    popoverValidation: false,
                    extraOptions,
                })

                // Set onload value (And after submission)
                if(value) {
                    if(type === 'single') {
                        if(Date.parse(value)) {
                            const date = moment(value).toDate()
                            inputInstance.setValue([ date ])
                        }
                    } else {
                        if(value.includes('/')) {
                            const values = value.split('/')
                            if(values.length == 2) {
                                const startValue = values[0].trim()
                                const endValue = values[1].trim()

                                const startDate = moment(startValue).toDate()
                                const endDate = moment(endValue).toDate()

                                inputInstance.setValue([ startDate, endDate ])
                            }
                        }
                    }
                }

                this.dateInputs.push(inputInstance)
            })
        }
    }

    initTimeRangeInputs() {
        const dateFields = this.form.find('.form-input[data-input-type="time-range"]')
        if (dateFields.length) {
            this.destroyTimeRangeInputs()
            dateFields.each((_, item) => {
                const inputInstance = new TimeRangeInput(item)
                this.timeRangeInputs.push(inputInstance)
            })
        }
    }

    /**
     * FileInputs (re)initialization
     * @param {Boolean} keepFiles
     */
    reInitDropzone(keepFiles = true) {
        const fileInputs = this.form.find('.form-input[data-input-type="file"]')

        if(fileInputs.length) {
            fileInputs.each((_, input) => {
                const $field = $(input)
                const fieldName = $field.attr('name')

                if(this.dropzoneInputs.hasOwnProperty(fieldName)) {
                    const inputInstance = this.dropzoneInputs[fieldName]
                    const oldInstance = inputInstance.getInstance()

                    // Keep files
                    let files = []
                    if (keepFiles && oldInstance) {
                        if (inputInstance.formbuilderInput) {
                            const storedFiles = inputInstance.getFiles()
                            Object.keys(storedFiles).forEach(key => {
                                const file = storedFiles[key]

                                // Create copy so browser can't know that's the same file
                                const fileCopy = new File([file], file.name, {
                                    type: file.type,
                                    noUpload: true,
                                })

                                fileCopy.uuid = file.upload.uuid
                                fileCopy.disableApiActions = true

                                files.push(fileCopy)
                            })
                        } else {
                            oldInstance.getQueuedFiles().forEach(file => {
                                files.push(file)
                            })
                        }
                    }

                    // Destroy "old" dropzone instance
                    // Destroy action will also trigger "removedFile" and call endPoints
                    oldInstance.destroy()
                    delete this.dropzoneInputs[fieldName]

                    // Initialize new one
                    const dropzoneInstance = new FileUploadInput(input, {
                        action: this.form.attr('action'),
                        endPoints: this.formEndPoints,
                    })

                    $(input).val('').trigger('change')

                    // Set files to new instance
                    if (keepFiles) {
                        const newInstance = dropzoneInstance.getInstance()
                        files.forEach(file => {
                            newInstance.addFile(file)
                        })
                    }

                    // Set instance to fields "store"
                    this.dropzoneInputs[fieldName] = dropzoneInstance
                } else {
                    const dropzoneInstance = new FileUploadInput(input, {
                        action: this.form.attr('action'),
                        endPoints: this.formEndPoints,
                    })

                    this.dropzoneInputs[fieldName] = dropzoneInstance
                }
            })
        }
    }

    initCreditCardExpiry() {
        const creditCardExpiryFields = this.form.find('.form-input[data-input-type="credit-card-expiration-date"]')
        if (creditCardExpiryFields.length) {
            this.destroyCreditCardExpiryInputs()

            creditCardExpiryFields.each((_, input) => {
                const inputInstance = new CreditCardExpiryInput(input)
                this.creditCardExpiryInputs.push(inputInstance)
            })
        }

    }

    initPassengersInputs() {
        const passengersFields = this.form.find('.form-input[data-input-type="passengers-input"]')
        if (passengersFields) {
            this.destroyPassengersInputs()
            passengersFields.each((_, passengerInput) => {
                const passengerInstance = new PassengerInput(passengerInput, {
                    values: { adults: parseInt($(passengerInput).val() || 1) },
                    popoverTemplate: this.form.find('.passengers-input-template').html(),
                    onChange: () => {}
                })

                this.passengersInputs.push(passengerInstance)
            })
        }
    }

    /**
     * Called after form submission
     * *Override if needed*
     */
    afterSubmit() {

    }

    getFormData(form) {
        const formData = new FormData(form)

        this.dateInputs.forEach(input => {
            if (input.type == 'range' && input.getValue().length) {
                formData.set($(input.input[0]).attr('name'), `${moment(input.getValue()[0]).format('Y-MM-DD')}/${moment(input.getValue()[1]).format('Y-MM-DD')}`)
            }

            if (input.type == 'single' && input.getValue().length) {
                formData.set($(input.input[0]).attr('name'), moment(input.getValue()[0]).format('Y-MM-DD'))
            }
        })

        /**
         * Get values from phoneNumber input
         */
        this.phoneNumberInputs.forEach(instance => {
            const values = instance.getValue()
            const inputName = instance.input.attr('name')

            // Remove from form data
            formData.delete(inputName)

            // Set by provided values
            const { number, areaCode, countryCode } = values
            if (number) {
                let value
                if (countryCode) {
                    value = `${areaCode}${number}`
                } else {
                    value = number
                }

                formData.append(inputName, value)
            }
        })

        /**
         * Dropzone files
         */
        Object.keys(this.dropzoneInputs).forEach(inputName => {
            const input = this.dropzoneInputs[inputName]
            const instance = input.getInstance()

            if (instance && instance.getQueuedFiles().length) {
                formData.delete(instance.element.name)

                instance.getQueuedFiles().forEach(file => {
                    let fieldName
                    if (instance.options.maxFiles) {
                        fieldName = `${instance.element.name}[]`
                    } else {
                        fieldName = instance.element.name
                    }

                    if (!input.formbuilderInput) {
                        formData.append(fieldName, file)
                    }
                })
            }
        })

        /**
         * Remove ones with flag
         */
        this.form.find('.hidden-no-send').each(function() {
            const $input = $(this)
            const inputName = $input.attr('name')
            const $fieldContainer = $input.closest('.field-container')

            if ($fieldContainer.hasClass('d-none')) {
                formData.delete(inputName)
            }
        })

        /**
         * Steps logic
         */
        if(this.stepInput) {
            const { steps } = this.stepInput.data()

            // Set "final step" flag
            const stepInputName = this.stepInput.attr('name')
            const isLastStep = parseInt(steps) === parseInt(this.step)

            if (isLastStep) {
                formData.set(stepInputName, 1)
            } else {
                formData.delete(stepInputName)
            }
        }

        return formData
    }

    updateForm(data) {
        const template = $(data).find('.template-content')
        const form = $(data).find(`form[name="${this.form.attr('name')}"]`)

        if (template.length) {
            this.form.addClass('d-none')
            this.form.closest('.offcanvas-body').append(template)
        }

        if (form.length) {
            // Set content from response
            this.form.empty()
            this.form.append(form.html())

            // Re-init fields
            this.initFields()

            if (this.form.find('.tab-pane').length) {
                if (this.form.find('.alert').length) {
                    this.step = 1
                    this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
                } else {
                    this.form.find('.tab-pane').removeClass('active show')
                    this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
                }
            }
        }

        setTimeout(() => {
            this.scrollAfterSubmit()
        }, 50)
    }

    reOpenForm() {
        this.toStep(1)
    }

    toNextStep() {
        this.step = this.step + 1
        this.form.find('.tab-pane').removeClass('active show')
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).find('.invalid-feedback').remove()
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).find('.is-invalid').removeClass('is-invalid')
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
    }

    /**
     * Go to step
     * @param {Number} step
     */
    toStep(step = 1) {
        this.step = step
        this.form.find('.tab-pane').removeClass('active show')
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).find('.invalid-feedback').remove()
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).find('.is-invalid').removeClass('is-invalid')
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
    }

    validateStep() {
        // Check if we have steps or just form
        const $fieldContainers = this.form.find('.tab-pane').length
            ? this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)
            : this.form

        // Fields
        const invalidFields = $fieldContainers.find('.is-invalid')
        if (invalidFields.length) {
            return false
        }

        // Invalid feedbacks (+recaptcha)
        const invalidFeedbackMsg = $fieldContainers.find('.invalid-feedback')
        if(invalidFeedbackMsg.length) {
            return false
        }

        return true
    }

    /**
     * Scroll after form submit (markup update)
     */
    scrollAfterSubmit() {
        if(this.validateStep()) {
            this.form.closest('.offcanvas-body').animate({scrollTop: 0}, 500)
        } else {
            let $scrollElement
            let $fieldContainer

            if (this.form.find('.tab-pane').length) {
                $fieldContainer = this.form.find('.tab-pane.active')
            } else {
                $fieldContainer = this.form
            }

            const $invalidField = $fieldContainer.find('.is-invalid:visible:first')
            const $invalidFeedback = $fieldContainer.find('.invalid-feedback:visible:first')

            $scrollElement = $invalidField.length
                ? $invalidField
                : $invalidFeedback.length ? $invalidFeedback : null

            if($scrollElement) {
                if (!isElementVisible($scrollElement[0], this.form.closest('.offcanvas-body')[0])) {
                    this.form.closest('.offcanvas-body').animate({
                        scrollTop: $scrollElement.offset().top - 50
                    }, 500)
                }
            } else {
                this.form.closest('.offcanvas-body').animate({scrollTop: 0}, 500)
            }
        }
    }

    /**
     * Clear error response (ajax)
     */
    clearErrorResponse() {
        const lastStep = this.form.find('.tab-pane.message-step')

        if (lastStep.length) {
            const flashMsgWrapper = lastStep.find('.flash-msg-wrapper')
            const errorMsgWrapper = lastStep.find('.error-msg-wrapper')

            if (flashMsgWrapper.length && errorMsgWrapper.length) {
                flashMsgWrapper.removeClass('d-none')
                errorMsgWrapper.html('').addClass('d-none')
            }
        }
    }

    /**
     * Error response handler (ajax)
     * @param {Object} errResponse
     */
    handleErrorResponse(errResponse = {}) {
        const lastStep = this.form.find('.tab-pane.message-step')

        if (lastStep.length) {

            const flashMsgWrapper = lastStep.find('.flash-msg-wrapper')
            const errorMsgWrapper = lastStep.find('.error-msg-wrapper')

            if (flashMsgWrapper.length && errorMsgWrapper.length) {
                const { title, status, detail } = errResponse

                let errorMarkup

                if (title && detail) {
                    errorMarkup = `
                        <div class="alert alert-danger" role="alert">
                            <h5 class="alert-heading mb-1">
                                ${title} with status: ${status}
                            </h5>
                            <p class="small">
                                ${detail ? detail.replace(/\n/g, "<br />") : ''}
                            </p>
                        </div>
                    `
                } else {
                    errorMarkup = `
                        <div class="alert alert-danger" role="alert">
                            <h5 class="alert-heading mb-1">
                                Internal server error
                            </h5>
                            <p class="small">
                                The server encountered an internal error or misconfiguration and was unable to complete your request.
                            </p>
                        </div>
                    `
                }


                flashMsgWrapper.addClass('d-none')
                errorMsgWrapper.html(errorMarkup)

                this.form.find('.tab-pane').removeClass('active show')
                lastStep.addClass('active show')
            }
        }
    }

    destroyPhoneNumbers() {
        this.phoneNumberInputs.forEach(instance => {
            instance.destroy()
        })
        this.phoneNumberInputs = []
    }

    destroySelects(keepValues = false) {
        this.selects.forEach(selectizeInstance => {
            const $select = selectizeInstance.$input

            // Destroy instance
            selectizeInstance.destroy()

            // Keep value (selectize.js bug)
            // https://stackoverflow.com/questions/27911184/selectize-js-destroy-method-changes-select-value
            if (keepValues) {
                const selectedValue = selectizeInstance.getValue() || ''
                $select.val(selectedValue)
            } else {
                $select.val('')
            }

            const inputId = $select.attr('id')
            $('html').off(`click.${inputId}`)
        })

        this.selects = []
    }

    destroyTextareas() {
        this.textareas.forEach(input => {
            input.destroy()
        })
        this.textareas = []
    }

    destroyDateInputs() {
        this.dateInputs.forEach(input => {
            input.destroy()
        })
        this.dateInputs = []
    }

    destroyTimeRangeInputs() {
        this.timeRangeInputs.forEach(input => {
            input.destroy()
        })
        this.timeRangeInputs = []
    }

    destroyCreditCardExpiryInputs() {
        this.creditCardExpiryInputs.forEach(input => {
            input.destroy()
        })

        this.creditCardExpiryInputs = []
    }

    destroyPassengersInputs() {
        this.passengersInputs.forEach(input => {
            input.destroy()
        })
        this.passengersInputs = []
    }
}

export default Forms
