/**
 * UserDetails step class
 */
import axios from 'axios'
import moment from 'moment'

import DateInput from '../../../../bricks/booking/quick-booking-panel/fields/DateInput'
import PhoneNumberInput from '../../../../components/fields/PhoneNumber'

class DetailsStep {
    constructor(domElement = null, {
        onSubmit = null
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provider propper DOM element for UserDetails class')
            return
        }

        const {
            country,
            language,
            displayRegions,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.localizedRegions = Object.keys(displayRegions).reduce((acc, k) => {
            acc[k.toLowerCase()] = displayRegions[k]
            return acc
          }, {})

        const {
            registerMessages
        } = window.errorMesages

        this.errorMesages = registerMessages

        this.onSubmit = null
        if (onSubmit && typeof onSubmit === 'function') {
            this.onSubmit = onSubmit
        }

        this.init()
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-step-submit', this.handleStepSubmit.bind(this))
    }

    /**
     * Remove Attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    init() {
        const phoneField = this.container.find('.form-input[data-input-type="phone-input"]')
        this.PhoneNumberInput = new PhoneNumberInput(phoneField[0], {
            initialCountry: this.userCountry, // Selected country from header
            localizedCountries: this.localizedRegions, // Localized regions
            options: {} // Extra options
        })

        const maxDate = moment().subtract(13, 'years').toDate()
        const defaultDate = moment().date(15).subtract(40, 'years').toDate()

        const dateInput = this.container.find('.form-input[data-input-type="date-input"]')
        this.DateInput = new DateInput(dateInput[0], {
            popoverValidation: false,
            extraOptions: {
                maxDate,
                now: defaultDate,
                minDate: null,
                position: 'above',
                dateFormat: 'd/m/Y'
                //appendTo: dateInput.parent()[0],
            }
        })
    }

    /**
     * Step submission action handler
     */
    async handleStepSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        const $button = $(event.currentTarget)

        if($button.hasClass('is-requesting')) {
            return
        }

        $button.addClass('is-requesting')

        // Validate recaptcha
        const recaptchaValid = await this.validateRecaptcha()
        if (!recaptchaValid) {
            $button.removeClass('is-requesting')
            return
        }

        const isValid = this.validateFields()
        setTimeout(() => {
            $button.removeClass('is-requesting')
            if(isValid) {
                if(this.onSubmit) {
                    this.onSubmit()
                }
            }
        }, 380)
    }

    /**
     * Form fields validation
     * @returns {Boolean} true - No for errors
     */
    validateFields() {
        let valid = true
        const fields = this.container.find('.form-input[type="text"], .form-input[type="tel"], .form-input[type="radio"]')

        fields.each((_, field) => {
            const $field = $(field)
            const { name } = $field.data()

            const isRequired = $field.prop('required')

            if(name === 'firstname' || name === 'lastname') {
                const value = $field.val()

                if(isRequired && !value) {
                    this.setFieldError($field, this.errorMesages.required_field)

                    valid = false
                    return true
                }
            }

            if(name === 'phone_number') {
                const { isValid } = this.PhoneNumberInput.validate()
                if(!isValid) {
                    valid = false
                    return true
                }
            }

            if(name === 'date_of_birth') {
                const isValidDate = this.DateInput.validate()
                if(!isValidDate) {
                    valid = false
                    return true
                }
            }

            if (name === 'gender') {
                if(isRequired) {
                    const isChecked = this.container.find(`.form-input[data-name="${name}"]:checked`).length
                    if (!isChecked) {
                        this.setFieldError($field, this.errorMesages.required_field)

                        valid = false
                        return true
                    }
                }
            }

            // Clear errors
            this.clearFieldError($field)
        })

        return valid
    }

    /**
     * Recaptcha validation method
     * @returns {Promise}
     */
    async validateRecaptcha() {
        /**
         * @todo Add this when we will be adding recaptcha again
         */
        return true

        const { validate, siteKey } = window.recaptcha
        let action = 'register_form'
        if (grecaptcha && validate && siteKey) {
            try {
                const token = await grecaptcha.execute(siteKey, { action: action })

                try {
                    const response = await axios.post(validate, {
                        'g-recaptcha-response': token,
                        'action': action
                    })

                    const { isValid } = response.data
                    if (!isValid) {
                        this.showAlert(this.errorMesages.recaptcha_validation_error)
                        return false
                    }

                    return true
                } catch (error) {
                    console.error(error)
                    this.showAlert(this.errorMesages.firebase_generic_error)
                    return false
                }
            } catch (error) {
                console.error(error)
                this.showAlert(this.errorMesages.recaptcha_couldnt_get_token)
                return false
            }
        }
    }

    /**
     * Values getter
     */
    getValues() {
        const fieldValues = {}
        const fields = this.container.find('.form-input[type="text"], .form-input[type="tel"], .form-input[type="checkbox"], .form-input[type="radio"]:checked')
        fields.each((_, field) => {
            const $field = $(field)
            const { name } = $field.data()

            if(name === 'phone_number') {
                const phoneValues = this.PhoneNumberInput.getValue()
                fieldValues['mobilePhone'] = phoneValues

            } else if(name === 'date_of_birth') {
                const dobValue = this.DateInput.getValue()
                if(dobValue.length) {
                    const dobDate = dobValue[0]

                    // Format & force utc
                    const dobFormatted = moment(dobDate).utc(true).toISOString()

                    fieldValues['dateOfBirth'] = dobFormatted
                }

            } else {
                if ($field.is(':checkbox')) {
                    fieldValues[name] = $field.is(':checked')
                } else {
                    const value = $field.val()
                    fieldValues[name] = value
                }
            }
        })

        return fieldValues
    }

    /**
     * Clear field values
     * @todo add clear feature
     */
    clearValues() {

    }

    /**
     * Set validation error on field
     * @param {jQuery} $field
     * @param {String} errorMessage
     */
    setFieldError($field, errorMessage = '') {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        // Set feedback message
        $feedback.text(errorMessage)

        // Set classes
        $field.addClass('is-invalid')
        $parent.addClass('is-invalid')
    }

    /**
     * Clear validation on field
     * @param {jQuery} $field
     */
    clearFieldError($field) {
        const $parent = $field.parent()

        $field.removeClass('is-invalid')
        $parent.removeClass('is-invalid')
    }

    /**
     * Show alert before form fields
     * @param {String} message
     */
    showAlert(message = null, type = 'danger') {
        const markup = `
            <div class="alert alert-${type} small alert-dismissible mb-6 mt-2 fade show" role="alert">
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `

        // Each of tabs have it's own alert container
        this.container.find('.user-register-form--alert-container').html(markup)
    }
}

export default DetailsStep
