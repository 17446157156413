import Forms from '../..'
import FlightInfo from './FlightInfo'
import FormRepeater from '../../../components/FormRepeater'

class GroupReservation extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.FormRepeater = null

        this.FlightsStore = {}
        this.FlightsDataStore = {}

        //this.toNextStep()
        this.addEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        if(this.FormRepeater) {
            this.FormRepeater.destroy()
            this.FormRepeater = null
        }

        Object.values(this.FlightsStore).forEach(item => item.destroy())

        this.FlightsStore = {}
        this.FlightsDataStore = {}

        this.form.off('click touch')
        this.form.off('change')
    }

    initFields() {
        this.initRepeaterFields()

        this.initPhone()
        this.initSelect()
        this.initDates()

        this.initFlightInfos()

        // Step input
        this.stepInput = this.form.find('.form-input[data-input-type="final_step_checkbox"]')
        this.stepInput = this.stepInput.length ? this.stepInput : null
    }

    addEventListeners() {
        // Back button logic
        this.form.on('click touch', '.btn-back', () => this.toPreviousStep())

        // Request type selection
        this.form.on('change', '.request-type-select', event => {
            const selectedValue = this.form.find('.request-type-select:checked').val()

            const $iataFieldContainer = this.form.find('.iata-type-field-container')
            const { showField } = $iataFieldContainer.data()

            if (selectedValue === showField) {
                $iataFieldContainer.removeClass('d-none')
            } else {
                $iataFieldContainer.addClass('d-none')
            }
        })

        // Flight type selection
        this.form.on('change', '.flight-type-select', (event) => {
            const selectedValue = this.form.find('.flight-type-select:checked').val()
            const $fieldsContainer = this.form.find('.flight-fields-container .field-container')

            $fieldsContainer.each((_, item) => {
                const $fieldContainer = $(item)
                const { visibility } = $fieldContainer.data()

                if(visibility.includes(selectedValue)) {
                    $fieldContainer.removeClass('d-none')
                    $fieldContainer.find('.form-label').addClass('required') // Since backend validation is dynamic also
                } else {
                    $fieldContainer.addClass('d-none')
                    $fieldContainer.find('.form-label').removeClass('required')
                }
            })
        })

        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            this.reOpenForm()
        })
    }

    afterSubmit() {
        // Update with saved data
        this.updateFlightsData()
    }

    /**
     * Triggered by "New request" button from last step
     */
    reOpenForm() {
        // Reset saved values by flight infos
        this.resetFlightInfos()

        // Go to first step
        this.toStep(1)
    }

    /**
     * Go to previous step
     */
    toPreviousStep() {
        this.step = this.step - 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        this.form.closest('.offcanvas-body').animate({ scrollTop: 0 }, 500)

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
        }, 125)
    }

    initRepeaterFields() {
        if(this.FormRepeater) {
            this.FormRepeater.destroy()
            this.FormRepeater = null
        }

        const $repeaterContainer = this.form.find('.formbuilder-container-repeater')
        this.FormRepeater = new FormRepeater($repeaterContainer[0], {
            addButtonClass: 'add-city btn btn-outline-primary my-3 p-2 ps-4 pe-5',
            removeButtonClass: 'remove-city btn btn-outline-danger my-3 p-2 ps-4 pe-5',
            collectionClass: '.formbuilder-container-block',
            onAdd: () => {
                this.initSelect(true)
                this.initDates()

                this.initFlightInfos()

                this.updateMultiCityLabels()
            },
            onRemove: () => {
                this.initSelect(true)
                this.initDates()

                this.initFlightInfos()

                this.updateMultiCityLabels()
            }
        })
    }

    /**
     * Init flight info components
     */
    initFlightInfos() {
        // Keep data
        this.saveFlightsData()

        // Destroy FlightInfo components & reset it's store
        this.clearFlightInfos()

        const $flightFieldsContainer = this.form.find('.flight-fields-container')
        const { routes } = $flightFieldsContainer.data()

        // Initialization
        const $departingFlight = this.form.find('.field-container.departure-flight-container')
        const DepartingFlightInfo = new FlightInfo($departingFlight[0], { routes })

        const $returningFlight = this.form.find('.field-container.return-flight-container')
        const ReturningFlightInfo = new FlightInfo($returningFlight[0], { routes })

        this.FlightsStore['departure'] = DepartingFlightInfo
        this.FlightsStore['return'] = ReturningFlightInfo

        const $multiCityContainer = this.form.find('.multi-city-flight-container .formbuilder-container-block')
        $multiCityContainer.each((_, item) => {
            const { index } = $(item).data()
            const CollectionFlightInfo = new FlightInfo(item, { routes })

            this.FlightsStore['collection'] = this.FlightsStore['collection'] || {}
            this.FlightsStore['collection'][index] = CollectionFlightInfo
        })

        // Update initialized fields with saved data
        this.updateFlightsData()
    }

    /**
     * Update "Flight" labels on multicity
     */
    updateMultiCityLabels() {
        const $labels = this.form.find('.formbuilder-container-block .block-label')
        $labels.each((index, item) => {
            const labelIndex = index + 1

            $(item).find('.index').text(labelIndex)
        })
    }

    saveFlightsData() {
        Object.keys(this.FlightsStore).forEach(key => {
            if (key !== 'collection') {
                this.FlightsDataStore[key] = this.FlightsStore[key].getData()
            } else {
                this.FlightsDataStore[key] = this.FlightsDataStore[key] || {}
                Object.keys(this.FlightsStore[key]).forEach(collectionKey => {
                    this.FlightsDataStore[key][collectionKey] = this.FlightsStore[key][collectionKey].getData()
                })
            }
        })
    }

    updateFlightsData() {
        Object.keys(this.FlightsDataStore).forEach(key => {
            if (key !== 'collection') {
                const data = this.FlightsDataStore[key]
                if (data) {
                    this.FlightsStore[key].setData(data)
                }
            } else {
                this.FlightsDataStore[key] = this.FlightsDataStore[key] || {}
                Object.keys(this.FlightsStore[key]).forEach(collectionKey => {
                    const data = this.FlightsDataStore[key][collectionKey]
                    if (data) {
                        this.FlightsStore[key][collectionKey].setData(data)
                    }
                })
            }
        })
    }

    resetFlightInfos() {
        Object.keys(this.FlightsStore).forEach(key => {
            if (key !== 'collection') {
                this.FlightsStore[key].resetFields()
            } else {
                this.FlightsDataStore[key] = this.FlightsDataStore[key] || {}
                Object.keys(this.FlightsStore[key]).forEach(collectionKey => {
                    this.FlightsStore[key][collectionKey].resetFields()
                })
            }
        })
    }

    clearFlightInfos() {
        Object.keys(this.FlightsStore).forEach(key => {
            if (key !== 'collection') {
                this.FlightsStore[key].destroy()
            } else {
                this.FlightsDataStore[key] = this.FlightsDataStore[key] || {}
                Object.keys(this.FlightsStore[key]).forEach(collectionKey => {
                    this.FlightsStore[key][collectionKey].destroy()
                })
            }
        })

        this.FlightsStore = {}
    }
}

export default GroupReservation
