/**
 * BidForBusinessClass component
 */

class BidForBusinessClass {
    /**
     * Contructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for BidForBusinessClass')
            return
        }
    }
}

export default BidForBusinessClass
