/**
 * Flight info step class component
 */
import axios from 'axios'
import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class FlightInfo {
    constructor(domElement = null) {
        this.form = $(domElement)
        if(!this.form.length) {
            console.error('Please provide proper DOM element for Complaint/FlightInfo class')
            return
        }

        // User language
        const {
            language,
        } = window.localeData
        this.userLanguage = language

        const $stepContainer = this.form.find('.flight-info-step')
        const { dataKey } = $stepContainer.data()
        const {
            routes,
            options,
        } = window[dataKey]

        this.routes = routes
        this.options = options

        this.originsData = this.options.origins
        this.defaultOriginsData = this.options.origins

        this.destinationsData = this.options.destinations
        this.defaultDestinationsData = this.options.destinations

        this.flightNumbersData = []
        this.flightNumberFilters = {
            date: null,
            origin: null,
            destination: null,
            directFlights: true,
            ajax: true,
        }

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.form.on('change', '.flight-info-step .flight-date-input', this.handleFlightDateChange.bind(this))
        this.form.on('change', '.flight-info-step .flight-from-select', this.handleOriginLocationChange.bind(this))
        this.form.on('change', '.flight-info-step .flight-to-select', this.handleDestinationLocationChange.bind(this))
        this.form.on('change', '.flight-info-step .flight-number-select', this.handleFlightNumberChange.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.form.off('change')
    }

    /**
     * Set options for all field (Call in "afterSubmission" method)
     */
    setOptions() {
        const $flightNumberInput = this.form.find('.flight-info-step .flight-number-select')
        const flightNumberInputSelectize = $flightNumberInput[0].selectize
        this.setInputOptions(flightNumberInputSelectize, this.flightNumbersData)

        const $originInput = this.form.find('.flight-info-step .flight-from-select')
        const originInputSelectize = $originInput[0].selectize
        this.setInputOptions(originInputSelectize, this.originsData)

        const $destinationInput = this.form.find('.flight-info-step .flight-to-select')
        const destinationSelectize = $destinationInput[0].selectize
        this.setInputOptions(destinationSelectize, this.destinationsData)

        this.setTicketNumberVisibility()
    }

    /**
     * Reset all field values & options (Called after form is closed)
     */
    resetFields() {
        this.originsData = this.defaultOriginsData
        this.destinationsData = this.defaultDestinationsData

        this.flightNumbersData = []
        this.flightNumberFilters = {
            date: null,
            origin: null,
            destination: null,
            directFlights: true,
            ajax: true,
        }

        this.setOptions()
    }

    /**
     * Flight date change handler
     * @param {Object} event
     */
    handleFlightDateChange(event) {
        const $input = $(event.currentTarget)
        const inputFpInstance = $input[0]._flatpickr

        if(inputFpInstance) {
            let selectedDate = null

            const { selectedDates } = inputFpInstance
            if(selectedDates.length) {
                selectedDate = inputFpInstance.formatDate(selectedDates[0], 'Y-m-d')

                this.flightNumberFilters = {
                    ...this.flightNumberFilters,
                    date: selectedDate
                }

                this.fetchFlightNumbersData()
            } else {
                // Clear options
                const $flightNumberInput = this.form.find('.flight-info-step .flight-number-select')
                const selectizeInstance = $flightNumberInput[0].selectize

                this.flightNumberFilters = {
                    ...this.flightNumberFilters,
                    date: null
                }

                this.flightNumbersData = []
                this.setInputOptions(selectizeInstance, this.flightNumbersData)
            }
        }
    }

    /**
     * Origin location change handler
     * @param {Object} event
     */
    handleOriginLocationChange(event) {
        const $input = $(event.currentTarget)
        const locationCode = $input.val()

        this.flightNumberFilters = {
            ...this.flightNumberFilters ,
            origin: locationCode,
        }

        this.fetchFlightNumbersData()
    }

    /**
     * Destination location change handler
     * @param {Object} event
     */
    handleDestinationLocationChange(event) {
        const $input = $(event.currentTarget)
        const locationCode = $input.val()

        this.flightNumberFilters = {
            ...this.flightNumberFilters ,
            destination: locationCode,
        }

        this.fetchFlightNumbersData()
    }

    /**
     * Fetch flight numbers for given filters data
     * @returns {Promise}
     */
    async fetchFlightNumbersData() {
        // Selected flight date is required field
        const { date } = this.flightNumberFilters
        if(!date) {
            return
        }

        const $flightNumberInput = this.form.find('.flight-info-step .flight-number-select')
        const selectizeInstance = $flightNumberInput[0].selectize

        try {
            // Lock input
            if(selectizeInstance) {
                selectizeInstance.lock()
            }

            // Create querries from filters
            const querries = createUrlParamsFromObject(this.flightNumberFilters)

            // Make request
            const requestUri = `${this.routes.flights}?${querries}`
            const response = await axios.get(requestUri)
            const { data } = response

            this.flightNumbersData = data.map(item => ({
                text: item,
                value: item,
            }))

            this.setInputOptions(selectizeInstance, this.flightNumbersData)

            // Unlock input
            if(selectizeInstance) {
                selectizeInstance.unlock()
            }

            return

        } catch(err) {
            // Unlock input
            if(selectizeInstance) {
                selectizeInstance.unlock()
            }

            console.error(err)
            throw err
        }
    }

    /**
     * Handle change of flight number value
     * @param {Object} event
     */
    handleFlightNumberChange(event) {
        this.setTicketNumberVisibility()
    }

    /**
     * Set visibility of ticketNumber input
     */
    setTicketNumberVisibility() {
        const flightNumberInput = this.form.find('.flight-info-step .flight-number-select')
        const ticketNumberContainer = this.form.find('.flight-info-step .ticket-number-container')

        if (this.isCharterFlight() || flightNumberInput.val() === '') {
            ticketNumberContainer.addClass('d-none')
        } else {
            ticketNumberContainer.removeClass('d-none')
        }
    }

    /**
     * Check if flight is charter-type
     * @returns {Boolean}
     */
    isCharterFlight() {
        const flightNumberInput = this.form.find('.flight-info-step .flight-number-select')
        const flightNumberValue = flightNumberInput.val()

        const regex = new RegExp(/\d{4}$/)
        if(regex.test(flightNumberValue) && flightNumberValue.replace(/[^0-9]/g,"").length === 4) {
            const numericValue = regex.exec(flightNumberValue)[0]
            if (numericValue && numericValue[0] == '9') {
                return true
            }
        } else {
            return false
        }
    }

    /**
     * Set options for input
     * @param {selectizeInstance} $input
     * @param {Array} options
     */
    setInputOptions(inputSelectizeInstance, options = []) {
        if(inputSelectizeInstance) {
            const currentValue = inputSelectizeInstance.getValue()
            //const optionsValues = options.map(item => item.value)
            //const isCurrentValueInNewOptions = optionsValues.includes(currentValue)

            // Clear options
            inputSelectizeInstance.clearOptions(true)
            inputSelectizeInstance.clearOptionGroups(true)

            // Add new options
            inputSelectizeInstance.addOption(options)
            inputSelectizeInstance.refreshOptions(false)

            // Keep selection of "old value" if it is in new options
            if (currentValue) {
                inputSelectizeInstance.addItem(currentValue, true)
            } else {
                inputSelectizeInstance.clear()
            }
        }
    }
}

export default FlightInfo
