import axios from 'axios'

class FlightInfo {
    constructor(domElement = null, {
        routes = {}
    }) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide propper DOM element for FlightInfo class')
            return
        }

        // User language
        const {
            language,
        } = window.localeData
        this.userLanguage = language

        // Routes
        this.routes = routes

        // Default options
        this.getInitialOptions()

        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('change', '.from-select', this.handleOriginLocationChange.bind(this))
        this.container.on('change', '.to-select', this.handleDestinationLocationChange.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('change')
    }

    /**
     * Data getter
     */
    getData() {
        return {
            originsData: this.originsData,
            destinationsData: this.destinationsData,
        }
    }

    /**
     * Data setter (Call in "afterSubmission" method)
     */
    setData({ originsData, destinationsData }) {
        this.originsData = originsData || this.originsData
        this.destinationsData = destinationsData || this.destinationsData

        const $originInput = this.container.find('.from-select')
        const originInputSelectize = $originInput[0].selectize
        this.setInputOptions(originInputSelectize, this.originsData)

        const $destinationInput = this.container.find('.to-select')
        const destinationSelectize = $destinationInput[0].selectize
        this.setInputOptions(destinationSelectize, this.destinationsData)
    }

    /**
     * Reset all field values & options (Called after form is closed)
     */
    resetFields() {
        this.originsData = this.defaultOriginsData
        this.destinationsData = this.defaultDestinationsData

        this.setData({})
    }

    /**
     * Origin location change handler
     * @param {Object} event
     */
    async handleOriginLocationChange(event) {
        const $input = $(event.currentTarget)
        const locationCode = $input.val()

        const $connectedInput = this.container.find('.to-select')
        const selectizeInstance = $connectedInput[0].selectize

        // Reset locations
        if (!locationCode) {
            this.destinationsData = this.defaultDestinationsData
            this.setInputOptions(selectizeInstance, this.destinationsData)

            return
        }

        try {
            if (selectizeInstance) {
                selectizeInstance.lock()
            }

            // Fetch & set origins options
            const requestUri = `${this.routes.destinations}?origin=${locationCode}&lang=${this.userLanguage}&directFlights=true&ajax=true`
            const response = await axios.get(requestUri)
            const { data } = response

            this.destinationsData = data.map(item => ({
                text: item.cityName,
                value: item.code,
            }))

            this.setInputOptions(selectizeInstance, this.destinationsData)

            if (selectizeInstance) {
                selectizeInstance.unlock()
            }
        } catch(err) {
            if (selectizeInstance) {
                selectizeInstance.unlock()
            }

            console.error(err)
            throw err
        }
    }

    /**
     * Destination location change handler
     * @param {Object} event
     */
    async handleDestinationLocationChange(event) {
        const $input = $(event.currentTarget)
        const locationCode = $input.val()

        const $connectedInput = this.container.find('.from-select')
        const selectizeInstance = $connectedInput[0].selectize

        // Reset locations
        if (!locationCode) {
            this.originsData = this.defaultOriginsData
            this.setInputOptions(selectizeInstance, this.originsData)

            return
        }

        try {
            if (selectizeInstance) {
                selectizeInstance.lock()
            }

            // Fetch & set origins options
            const requestUri = `${this.routes.origins}?destination=${locationCode}&lang=${this.userLanguage}&directFlights=true&ajax=true`
            const response = await axios.get(requestUri)
            const { data } = response

            this.originsData = data.map(item => ({
                text: item.cityName,
                value: item.code,
            }))

            this.setInputOptions(selectizeInstance, this.originsData)

            if (selectizeInstance) {
                selectizeInstance.unlock()
            }
        } catch(err) {
            if (selectizeInstance) {
                selectizeInstance.unlock()
            }

            console.error(err)
            throw err
        }
    }

    /**
     * Get initial location options (set inside selectize)
     */
    getInitialOptions() {
        const $fromInput = this.container.find('.from-select')
        const originOptions = $fromInput[0].selectize.options

        const $toInput = this.container.find('.to-select')
        const destinationOptions = $toInput[0].selectize.options

        const originsData = Object.values(originOptions).map(item => ({
            value: item.value,
            text: item.text
        }))

        const destinationsData = Object.values(destinationOptions).map(item => ({
            value: item.value,
            text: item.text
        }))

        this.originsData = originsData
        this.defaultOriginsData = originsData

        this.destinationsData = destinationsData
        this.defaultDestinationsData = destinationsData
    }

    /**
     * Set options for input
     * @param {selectizeInstance} $input
     * @param {Array} options
     */
    setInputOptions(inputSelectizeInstance, options = []) {
        if(inputSelectizeInstance) {
            const currentValue = inputSelectizeInstance.getValue()
            const optionsValues = options.map(item => item.value)
            const isCurrentValueInNewOptions = optionsValues.includes(currentValue)

            // Clear options
            inputSelectizeInstance.clearOptions(true)
            inputSelectizeInstance.clearOptionGroups(true)

            // Add new options
            inputSelectizeInstance.addOption(options)
            inputSelectizeInstance.refreshOptions(false)

            // Keep selection of "old value" if it is in new options
            if (isCurrentValueInNewOptions) {
                inputSelectizeInstance.addItem(currentValue, true)
            } else {
                inputSelectizeInstance.clear()
            }
        }
    }

}

export default FlightInfo
