import Forms from '../..'
import FlightInfo from './FlightInfo'

class CharterSalesForm extends Forms {
    constructor(domElement = null) {
        super(domElement)

        /*
        const { routes } = this.form.find('.flight-fields-container').data()

        const departureFlightContainer = this.form.find('.departure-flight-container')
        this.departureFlight = new FlightInfo(departureFlightContainer[0], { routes })

        const returnFlightContainer = this.form.find('.return-flight-container ')
        this.returnFlight = new FlightInfo(returnFlightContainer[0], { routes })

        this.toStep(2)
        */

        this.addEventListeners()
    }

    addEventListeners() {
        // Back button logic
        this.form.on('click touch', '.btn-back', () => this.toPreviousStep())

        // Flight type selection
        this.form.on('change', '.flight-type-select', (event) => {
            const selectedValue = this.form.find('.flight-type-select:checked').val()
            const $returnFlightContainer = this.form.find('.return-flight-container')

            if (selectedValue === 'round_trip') {
                $returnFlightContainer.removeClass('d-none')
                $returnFlightContainer.find('.form-label').addClass('required')
            } else {
                $returnFlightContainer.addClass('d-none')
                $returnFlightContainer.find('.form-label').removeClass('required')
            }
        })

        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            this.reOpenForm()
        })
    }

    afterSubmit() {
        //console.log(this.departureFlight, this.returnFlight);
    }

    /**
     * Triggered by "New request" button from last step
     */
    reOpenForm() {
        // Reset saved values by flight infos
        //this.resetFlightInfos()

        // Go to first step
        this.toStep(1)
    }

    /*
    resetFlightInfos() {
        this.departureFlight.resetFields()
        this.returnFlight.resetFields()
    }
    */

    /**
     * Go to previous step
     */
    toPreviousStep() {
        this.step = this.step - 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        this.form.closest('.offcanvas-body').animate({ scrollTop: 0 }, 500)

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
        }, 125)
    }
}

export default CharterSalesForm
