import axios from "axios"

class NewsletterForm {
    constructor(domElement = null) {
        this.form = $(domElement)

        if(!this.form.length) {
            return
        }

        if (!window.sitecoreConfig) {
            console.error('Missing sitecore mailing list configuration in enviroment')
        }

        this.mailingListID = window.sitecoreConfig.sitecore_mailing_list_newsletter

        const {
            country,
            language,
        } = window.localeData

        this.userCountry = country
        this.userLanguage = language

        this.emailInput = this.form.find('.newsletter-email-input')
        this.submitBtn = this.form.find('.btn-submit')
        this.emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

        this.init()
    }

    init() {
        this.emailInput.on('focus', () => {
            const { originalText } = this.submitBtn.data()
            this.emailInput.removeClass('is-invalid')
            this.emailInput.parent().removeClass('is-invalid')

            this.submitBtn.contents().filter(function() {
                return this.nodeType == 3
            }).remove()
            this.submitBtn.append(originalText)
        })

        this.form.on('submit', async (event) => {
            const { action } = this.form.data()
            const { successMessage } = this.submitBtn.data()
            event.preventDefault()

            // Disable multiple requests
            if (!this.validateFields() || this.submitBtn.hasClass('is-requesting')) {
                return
            }

            const formData = new FormData(this.form[0])

            try {
                this.submitBtn.addClass('is-requesting')

                const payload = this.createPayload(formData.get('userEmail'))
                await axios.post(action, payload)

                this.submitBtn.contents().filter(function() {
                    return this.nodeType == 3
                }).remove()
                this.submitBtn.append(successMessage)
                this.emailInput.val('')
                this.submitBtn.removeClass('is-requesting')
            } catch (error) {
                this.submitBtn.removeClass('is-requesting')
                console.error(error)
                throw error
            }
        })
    }

    validateFields() {
        if (this.emailInput.prop('required') && !this.emailInput.val()) {
            const { required } = this.emailInput.data()
            // Set invalid classes
            this.emailInput.addClass('is-invalid')
            this.emailInput.parent().addClass('is-invalid')

            this.emailInput.parent().next('.invalid-feedback').empty().html(required)

            return false
        }

        if (!this.emailRegex.test(this.emailInput.val())) {
            const { invalid } = this.emailInput.data()
            // Set invalid classes
            this.emailInput.addClass('is-invalid')
            this.emailInput.parent().addClass('is-invalid')

            this.emailInput.parent().next('.invalid-feedback').empty().html(invalid)

            return false
        }

        return true
    }

    /**
     * Create payload
     */
    createPayload(email = '') {
        let payload = {
            email,
            addPromoCode: true,
            mailingListId: this.mailingListID,
            lang: this.userLanguage,

            // @todo add this if needed
            /*
            hasExternalDoubleOptIn: false,
            customFields: [
                {
                  "name": "age",
                  "value": "25"
                },
                {
                  "name": "country",
                  "value": "USA"
                }
            ],
            */
        }

        return payload
    }
}

export default NewsletterForm
