/**
 * ContentBundle main script file
 */

// Components
import ContentPagesList from './components/ContentPagesList'
import ImageGallery from './components/ImageGallery'
import AnchorScroll from './utils/AnchorScroll'

$('.content-pages-list--container').each(function() {
    new ContentPagesList(this)
})

$('.image-gallery').each(function() {
    new ImageGallery(this)
})

new AnchorScroll