/**
 * Bank account class component
 */

class BankAccount {
    /**
     * BankAccount class component constructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for Complaint/BankAccount class')
            return
        }

        this.form = this.container.closest('form')

        this.nextButton = this.container.parent().find('button.next')
        this.countryHolderSelect = this.container.find('.country-holder-select')
        this.countryInfoSelect = this.container.find('select.country-info-select')

        this.init()
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    setEventListeners() {
        this.countryHolderSelect.on('change', this.handleCountryHolderChange.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    init() {
        const { countriesData } = this.container.data()
        this.countriesData = countriesData

        this.countryHolderValue = this.countryHolderSelect.val()

        const selectizeInstance = this.countryInfoSelect[0].selectize
        if(selectizeInstance) {
            selectizeInstance.lock()
            selectizeInstance.setValue(this.countryHolderValue)
        }

        this.setFieldsByCountryHolder()
        this.setAccountHolderFieldValue()
    }

    handleCountryHolderChange(event) {
        this.countryHolderValue = this.countryHolderSelect.val()

        const selectizeInstance = this.countryInfoSelect[0].selectize
        if(selectizeInstance) {
            selectizeInstance.setValue(this.countryHolderValue)
        }

        this.setFieldsByCountryHolder()
    }

    setFieldsByCountryHolder() {
        if(!this.countryHolderValue) {
            this.nextButton.prop('disabled', true)
            this.container.find('.bank-field-container').addClass('d-none')
            this.container.find('.top-text-label').removeClass('d-none')
            return
        }

        let selectedField
        Object.keys(this.countriesData).forEach(key => {
            const values = this.countriesData[key]

            if(values.includes(this.countryHolderValue)) {
                selectedField = key
            }
        })

        selectedField = selectedField || 'notListed'
        this.container.find('.bank-field-container').each((_, item) => {
            const $field = $(item)
            const { list } = $field.data()

            if (list.includes(selectedField)) {
                $field.removeClass('d-none')
            } else {
                $field.addClass('d-none')
            }
        })

        if(selectedField === 'notAvailable') {
            this.nextButton.prop('disabled', true)
            this.container.find('.top-text-label').addClass('d-none')
        } else {
            this.nextButton.prop('disabled', false)
            this.container.find('.top-text-label').removeClass('d-none')
        }

        this.container.find('.bank-fields-container').attr('data-country', selectedField)
    }

    /**
     * Set value to Account holder by "Authorized person value"
     */
    setAccountHolderFieldValue() {
        const $accountHolderFieldContainer = this.container.find('.bank-field-container[data-name="account_information_name_and_surname"]')
        const $field = $accountHolderFieldContainer.find('.form-control')
        const $label = $accountHolderFieldContainer.find('.form-label')

        const { value, label } = $accountHolderFieldContainer.data()

        if(value) {
            $field.val(value)
        }

        if(label) {
            $label.text(label)
            $field.attr('placeholder', label)
        }
    }
}

export default BankAccount
