import moment from 'moment'
import flatpickr from 'flatpickr'

/**
 * Format dates using flatpickr's custom localization logic
 */
const formatDates = () => {
    const $dates = $('.js-date-format')
    $dates.each((_, item) => {
        const $dateContainer = $(item)
        if (!$dateContainer.hasClass('js-formatted')) {
            const {
                datePicker
            } = window.localeData

            // Craete fpInstance on dummy div & set localization
            const fpInstance = new flatpickr($('<div />')[0], {
                locale: datePicker
            })

            // Parse date string abd force UTC
            const { dateStr, dateFormat } = $dateContainer.data()
            const parsedDate = moment(dateStr).utc(true).toDate()

            // Format provided date by localization
            const formattedDate = fpInstance.formatDate(parsedDate, dateFormat)

            // Set content
            $dateContainer.html(formattedDate).addClass('js-formatted')
        }
    })
}

export {
    formatDates
}
