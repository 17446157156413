/**
 * PersonalData step class component
 */
class PersonalData {
    /**
     * Constructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.form = $(domElement)
        if(!this.form.length) {
            console.error('Please provide proper DOM element for Complaint/PersonalData class')
            return
        }

        this.setEventListeners()
        this.setSubmitButtonState()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.form.on('blur', '.personal-data-step .email-input', event => {
            this.setSubmitButtonState(true)
            this.validateEmailRepeat()
        })

        this.form.on('blur', '.personal-data-step .email-input-repeat', event => {
            this.setSubmitButtonState(true)
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.form.off('input')
    }

    /**
     * Set submit button state
     */
    setSubmitButtonState(showError = false) {
        const $emailField = this.form.find('.personal-data-step .email-input')
        const $emailRepeatField = this.form.find('.personal-data-step .email-input-repeat')

        this.validateInput($emailField, showError)
        this.validateInput($emailRepeatField, showError)

        //$submitButton.prop('disabled', !isValid)
    }

    /**
     * Single input validation
     * @param {Jquery} $input
     */
    validateInput($input, showError = true) {
        // If error was already set don't show another
        const $inputContainer = $input.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.form-input-error-msg')
        if ($inputErrorMsg.length) {
            return false
        }

        if (!this.validateMailStructure($input, showError)) {
            return false
        }

        if (!this.validateEmailRepeat(showError)) {
            return false
        }

        return true
    }

    /**
     * Check if field is empty
     * @param {JQuery} $input
     * @returns {Boolean}
     */
    validateNotBlank($input, showError = true) {
        const $inputContainer = $input.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        $input.removeClass('is-invalid')
        $inputContainer.removeClass('is-invalid')

        $inputErrorMsg.addClass('d-none')
        $inputErrorMsg.html('')

        if (!$input.val().length && showError) {
            $input.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            $inputErrorMsg.removeClass('d-none')
            $inputErrorMsg.html(msg.empty)

            return false
        }

        return true
    }

    /**
     * Check for e-mail structure
     * @param {Juqery} $input
     * @returns {Boolean}
     */
    validateMailStructure($input, showError = true) {
        const $inputContainer = $input.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        $input.removeClass('is-invalid')
        $inputContainer.removeClass('is-invalid')

        $inputErrorMsg.addClass('d-none')
        $inputErrorMsg.html('')

        // 99.99% accuracy => RFC 5322 Format
        const regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

        if (!regex.test($input.val()) && showError) {
            $input.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            $inputErrorMsg.removeClass('d-none')
            $inputErrorMsg.html(msg.invalid)

            return false
        }

        return true
    }

    /**
     * Check wheter values are same
     * @returns {Boolean}
     */
    validateEmailRepeat(showError = true) {
        const $emailField = this.form.find('.personal-data-step .email-input')
        const $emailRepeatField = this.form.find('.personal-data-step .email-input-repeat')

        const $inputContainer = $emailRepeatField.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        const isSame = $emailField.val().length && $emailField.val() === $emailRepeatField.val()

        if(!isSame && $emailRepeatField.val().length && showError) {
            $emailRepeatField.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            $inputErrorMsg.removeClass('d-none')
            $inputErrorMsg.html(msg.no_match)
        } else {
            $emailRepeatField.removeClass('is-invalid')
            $inputContainer.removeClass('is-invalid')

            $inputErrorMsg.addClass('d-none')
            $inputErrorMsg.html('')
        }

        return isSame
    }
}

export default PersonalData
