// https://richtexteditor.com/demos/pastefromexcel.aspx

class RichText {
    constructor(domElement = null) {
        this.wrapperContainer = $(domElement)
        
        if(!this.wrapperContainer.length) {
            console.error('Please provide proper DOM element for RichText')
            return
        }

        this.container = this.wrapperContainer.find('.rich-text-editor')

        if (this.container.length) {
            const { pimcoreInput } = this.container.data()
            this.pimcoreInput = this.container.parent().find(`.pimcore_editable[data-real-name="${pimcoreInput}"]`)
        }
        
        const { brickIndex } = this.wrapperContainer.data()
        this.tableOriginal = this.wrapperContainer.find(`#excel-table-${ brickIndex }-original table`)[0]
        this.newTable = this.wrapperContainer.find(`#excel-table-${ brickIndex }`)

        this.config = {}
        this.editorInstance = null

        this.getConfig()
        this.init()
        if (this.container.length) {
            this.addEventListeners()
        }
    }

    init() {
        if (this.container.length) {
            this.editorInstance = new RichTextEditor(this.container[0], this.config)
            // Prevent pimcore from adding mask to iframe
            $(this.editorInstance.iframe).attr('data-type', 'pimcore_video_editable')
        }

        const tableData = this.tableToJson(this.tableOriginal)
        this.newTable.empty()
        this.newTable.append(this.createTable(tableData))
    }

    addEventListeners() {
        this.editorInstance.attachEvent('change', () => {
            const editorHTML = this.editorInstance.getHTMLCode()
            let cleanedHTML = editorHTML
            cleanedHTML = editorHTML.replace(/<table.*?>/, '<table>')
                .replace(/<tr.*?>/g, '<tr>')
                .replace(/<td.*?>/g, '<td style="border-right: 1px solid #000000; border-bottom: 1px solid #000000;">')
            if ($(editorHTML).find('table').attr('style') || $(editorHTML).filter('table').attr('style')) {
                this.editorInstance.setHTMLCode(cleanedHTML)
            }
            this.pimcoreInput.find('.pimcore_wysiwyg').empty()
            this.pimcoreInput.find('.pimcore_wysiwyg').append(cleanedHTML)
        })
    }

    getConfig() {
        this.config.toolbar = 'tabletoolbar'
        this.config.toolbar_tabletoolbar = '{inserttable}'

        // Remove unwanted table buttons
        this.config.controltoolbar_TD = '{menu_tablerow,menu_tablecolumn}'
        this.config.subtoolbar_table = ''
        this.config.subtoolbar_tablecell = ''

        this.config.url_base = '/admin-static/js/richtexteditor'

        this.config.editorResizeMode = 'height'
        this.config.showFloatParagraph = false
        this.config.showPlusButton = false
        this.config.showTagList = false
        this.config.showStatistics = false
    }

    tableToJson(table) {
        var data = {
            headers: [],
            rows: []
        }
        
        if (!table || !table.rows.length) {
            return data
        }

        // first row needs to be headers
        var headers = []
        for (var i=0; i<table.rows[0].cells.length; i++) {
            let content = ''
            $.parseHTML(table.rows[0].cells[i].innerHTML).forEach(element => {
                content += element.textContent
            })
            headers[i] = content
        }

        data.headers = headers

        // go through cells
        for (var i=1; i<table.rows.length; i++) {

            var tableRow = table.rows[i]
            var rowData = []

            for (var j=0; j<tableRow.cells.length; j++) {
                let content = ''
                $.parseHTML(tableRow.cells[j].innerHTML).forEach(element => {
                    content += element.textContent
                })
                rowData[j] = content
            }
            data.rows.push(rowData)
        }       

        return data
    }

    createTable(data) {
        let header = `
            <tr>
                ${data.headers.map(header => {
                    return `
                        <td class="p-3 p-md-4">
                            <p>${header}</p>
                        </td>
                    `
                }).join('')}
            </tr>
        `

        let rows = `
            ${data.rows.map((row, index) => {
                if (index + 1 == data.rows.length) {
                    return `
                        <tr>
                            ${row.map(cell => {
                                return `
                                    <td class="border-0 p-3 p-md-4">
                                        <p class="text-dark">${cell}</p>
                                    </td>
                                `
                            }).join('')}
                        </tr>
                    `
                } else {
                    return `
                        <tr>
                            ${row.map(cell => {
                                return `
                                    <td class="p-3 p-md-4">
                                        <p class="text-dark">${cell}</p>
                                    </td>
                                `
                            }).join('')}
                        </tr>
                    `
                }
            }).join('')}
        `

        return `
            <tbody>
                ${header}
                ${rows}
            </tbody>
        `
    }
}

export default RichText