/**
 * Project js code entry point
 */

// Attach select2
require('@selectize/selectize')

// This will import full js code from both Content & Areabrick bundles
// For partial import, use it like this => "import 'AreaBrickBundle/js/frontend/components/Drawers'"

import 'ContentBundle/js/frontend' // => Full ContentBundle js code import
import 'AreaBrickBundle/js/frontend' // => Full AreaBrickBundle js code import

import './utils/simplebar'
import './plugins/intlTel'

import './googlePlayBanner'

// Firebase
import FireBase from './utils/firebase'
window.FireBase = new FireBase()

// FCM
import FCM from './utils/firebase-messaging'
window.FCM = new FCM()

// User auth
import UserAuth from './user-pages/auth'
window.UserAuth = new UserAuth({})

// Notifications
import Notifications from './components/Notifications'
if($('.notifications-container').length) {
    new Notifications($('.notifications-container')[0])
}

// Header
import Header from './segments/header'
const appHeader = new Header($('header')[0])

// FAQ
import FAQSearch from './segments/faq/search'
new FAQSearch($('.faq-search-container')[0])

// Emergency page
import EmergencyPage from './pages/emergencyPage'
if ($('.emergency-page-container').length) {
    new EmergencyPage($('.emergency-page-container')[0])
}

import UserLogin from './user-pages/auth/login'
import UserRegister from './user-pages/auth/register'
import PasswordForgot from './user-pages/auth/password-forgot'

if ($('.user-login-drawer').length) {
    new UserLogin($('.user-login-drawer')[0], {
        onAuthChange: () => {
            appHeader.updateUserData()
        }
    })
}

if($('.user-register-drawer').length) {
    new UserRegister($('.user-register-drawer')[0], {
        onAuthChange: () => appHeader.updateUserData()
    })
}

if($('.password-forgot-drawer').length) {
    new PasswordForgot($('.password-forgot-drawer')[0])
}

// User Profile
import UserProfile from './user-pages/user-profile'
if ($('.user-profile-page-container').length) {
    new UserProfile($('.user-profile-page-container')[0])
}

// Booking panels
import MainBookingPanel from './bricks/booking/main-booking-panel'
import QuickBookingPanel from './bricks/booking/quick-booking-panel'

$('.main-booking-panel').each(function() {
    new MainBookingPanel(this)
})

$('.quick-booking-panel').each(function() {
    new QuickBookingPanel(this)
})

// Flight schedule
import FlightSchedule from './bricks/flight-schedule'
$('.flight-schedule').each(function() {
    new FlightSchedule(this)
})

// Flight status
import FlightStatus from './bricks/flight-status'
$('.flight-status').each(function() {
    new FlightStatus(this)
})

// Find my trip
import FindMyTrip from './bricks/find-my-trip'
$('.find-my-trip').each(function() {
    new FindMyTrip(this)
})

// Top Offers Carousel
import TopOffersCarousel from './bricks/top-offers-carousel'
$('.top-offers-carousel').each(function() {
    new TopOffersCarousel(this)
})

// HeroBanner
import HeroBanner from './bricks/hero-banner'
$('.hero-banner').each(function() {
    new HeroBanner(this)
})

// Search news
import SearchNewsWidget from './bricks/search-news'
$('.search-news-widget').each(function() {
    new SearchNewsWidget(this)
})

// Newsletter
import NewsletterForm from './components/newsletter-form/index'
$('.newsletter-form').each(function() {
    new NewsletterForm(this)
})

// Cargo agents
import CargoAgents from './bricks/cargo-agents'
$('.cargo-agents').each(function() {
    new CargoAgents(this)
})

// Forms
import './utils/recaptcha'

import Forms from './forms/index'
import NDC from './forms/exception-forms/NDC'
import Refund from './forms/exception-forms/Refund'
import ApplyForVoucher from './forms/exception-forms/ApplyForVoucher'
import ServiceComplaint from './forms/exception-forms/ServiceComplaint'
import CharterSalesForm from './forms/exception-forms/CharterSales'
import ComplaintForm from './forms/exception-forms/Complaint'
import BaggageComplaint from './forms/exception-forms/BaggageComplaint'
import GroupReservation from './forms/exception-forms/GroupReservation'
$('form.formbuilder').each(function() {
    switch (this.id) {
        case 'Complaint':
            new ComplaintForm(this)
            break
        case 'Refund':
            new Refund(this)
            break
        case 'NDC':
            new NDC(this)
            break
        case 'ApplyForVoucher':
            new ApplyForVoucher(this)
            break
        case 'ServiceComplaint':
            new ServiceComplaint(this)
            break
        case 'CharterSalesRequest':
            new CharterSalesForm(this)
            break
        case 'BaggageClaim':
            new BaggageComplaint(this)
            break
        case 'GroupReservation':
            new GroupReservation(this)
            break
        default:
            new Forms(this)
    }
})

// DesiredLocations forms
import DesiredLocationForm from './forms/DesiredLocationForm'
$('.desired-location-areabrick-container').each(function() {
    new DesiredLocationForm(this)
})

// Check in panel
import CheckInPanel from './bricks/check-in'
$('.check-in-panel').each(function() {
    new CheckInPanel(this)
})

// Bid for business class
import BidForBusinessClass from './bricks/bid-for-business-class'
$('.bid-for-business-class').each(function() {
    new BidForBusinessClass(this)
})

// External links
import LinksUtil from './utils/links'

window.LinksUtil = new LinksUtil()
window.LinksUtil.setLinks()

// Date fortmatter
import { formatDates } from './utils/date-formatter'
formatDates()

// Rich text editor
import RichText from './ritchTextEditor'
$('.excel-table-brick').each(function() {
    new RichText(this)
})