import Inputmask from "inputmask"
//import moment from "moment"

class CreditCardExpiryInput {
    constructor(domElement = null) {
        this.input = $(domElement)
        if(!this.input.length) {
            console.error('Please provide proper DOM element for CreditCardExpiryInput class')
            return
        }

        this.imInstance = null

        this.init()
    }

    init() {
        this.imInstance = new Inputmask({
            alias: 'datetime',
            inputFormat: 'mm/yy',
            //min: moment().add(1, 'M').format('MM/YY'),
            //max: moment().add(10, 'Y').format('MM/YY')
        }).mask(this.input[0])
    }

    destroy() {
        this.imInstance.remove()
        this.imInstance = null
    }
}

export default CreditCardExpiryInput
