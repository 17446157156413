class TextArea {
    constructor(domElement = null) {
        this.input = $(domElement)
        if(!this.input.length) {
            console.error('Please provide proper DOM element for TextArea class!')
            return
        }

        this.container = this.input.closest('.form-textarea')
        this.counter = this.container.find('.form-counter')
        this.maxCount = this.counter.data('max')

        if (this.counter.length) {
            this.setCounter()
            this.addEventListeners()
        }
    }

    /**
     * Destructor method
     */
    destroy() {
        this.counter.html('')
        this.removeEventListeners()
    }

    addEventListeners() {
        this.input.on('keydown', this.handleKeydown.bind(this))
        this.input.on('keyup', this.handleKeyup.bind(this))
        this.input.on('paste', this.handlePaste.bind(this))
    }

    removeEventListeners() {
        this.input.off('keydown')
        this.input.off('keyup')
        this.input.off('paste')
    }

    handleKeyup(event) {
        this.setCounter()
    }

    handleKeydown(event) {
        const count = this.getCount()
        if (event.keyCode != 8 && event.keyCode != 46 && count >= this.maxCount) {
            event.preventDefault()
            return
        }
    }

    handlePaste(event) {
        const clipboardData = event.clipboardData || window.clipboardData || event.originalEvent.clipboardData
        if (clipboardData) {
            event.preventDefault()

            const currentValue = this.input.val()
            const pastedContent = clipboardData.getData('text')

            const newLines = pastedContent.match(/(\r\n|\n|\r)/g)
            const additions = newLines ? newLines.length : 0

            const value = pastedContent.substr(0, this.maxCount + additions)

            this.input.val(`${currentValue}${value}`)
            this.setCounter()
        }
    }

    getCount() {
        const value = this.input.val()

        const newLines = value.match(/(\r\n|\n|\r)/g)
        const additions = newLines ? newLines.length : 0

        return value.length + additions
    }

    setCounter() {
        const currentValue = this.getCount()
        this.counter.html(`${currentValue}/${this.maxCount}`)
    }
}

export default TextArea
