class AnchorScroll {
    constructor() {
        this.history_support = !!(window.history && window.history.pushState)
        this.anchor_regex = /^#[^ ]+$/

        this.init()
    }

    /**
     * Establish events, and fix initial scroll position if a hash is provided.
     */
    init() {
        this.scrollToCurrent()

        $(window).on('hashchange', $.proxy(this, 'scrollToCurrent'))
        $('body').on('click', 'a', $.proxy(this, 'delegateAnchors'))
    }

    /**
     * Return the offset amount to deduct from the normal scroll position.
     * Modify as appropriate to allow for dynamic calculations
     */
    getFixedOffset() {
        return this.getHeaderHeight()
    }

    /**
     * Attempt to get the header height including padding and margin.
     */
    getHeaderHeight() {
        if ($('header').length > 0) {
            return $('header').outerHeight(true)

        } else if ($('.header').length > 0) {
            $('.header').outerHeight(true)

        } else {
            return 0
        }
    }

    /**
     * If the provided href is an anchor which resolves to an element on the
     * page, scroll to it.
     * @param  {String} href
     * @return {Boolean} - Was the href an anchor.
     */
    scrollIfAnchor(href, pushToHistory) {
        let match, anchorOffset
  
        if (!this.anchor_regex.test(href)) return false
  
        match = document.getElementById(href.slice(1))
  
        if (match) {
            anchorOffset = $(match).offset().top - this.getFixedOffset()
            $('html, body').animate({ scrollTop: anchorOffset }, { duration: 200, easing: 'linear' })
  
            // Add the state to history as-per normal anchor links
            if (this.history_support && pushToHistory) {
                window.history.pushState({}, window.document.title, window.location.pathname + href)
            }
        }
  
        return !!match;
    }

    /**
     * Attempt to scroll to the current location's hash.
     */
    scrollToCurrent(e) { 
        if (this.scrollIfAnchor(window.location.hash) && e) {
            e.preventDefault()
        }
    }

    /**
     * If the click event's target was an anchor, fix the scroll position.
     */
    delegateAnchors(e) {
        if (this.scrollIfAnchor(e.target.getAttribute('href'))) {
            e.preventDefault()
        }
    }
}

export default AnchorScroll