/**
 * Deeplinking class
 * https://docs.google.com/document/d/1v06e4IlEHvCyOZM5hGVhC_DgRnc5y5tCKJe-df_Gkqc/edit
 * https://docs.google.com/document/d/1YPGClfDUR2GrN4P3vNoMT0G8QrI6YDtd/edit
 */
import moment from 'moment'
import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class DeepLinking {
    constructor(baseUrl = null) {
        this.baseUrl = baseUrl

        const {
            country,
            language,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'de' : country

        this.defaultValues = {
            class: 'Economy',
            locale: this.localeFormatter(this.userLanguage, this.userCountry),
            pointOfSale: this.pointOfSaleFormatter(this.userCountry),
            activeMonth: null,
        }
    }

    /**
     * Format data and redirect to SABRE
     * @param {Object} payload booking values
     * @param {boolean} newWindow
     */
    engage(payload = {}, newWindow = true) {
        const data = this.formatPayload(payload)
        const querries = createUrlParamsFromObject(data)

        const redirectUrl = `${this.baseUrl}?${querries}`
        if (newWindow) {
            window.open(redirectUrl, '_blank')
        } else {
            window.open(redirectUrl, '_self')
        }
    }

    /**
     * Payload formatter
     * @param {Object} data
     */
    formatPayload(payload = {}) {
        const { tripType, payloadValues } = payload

        const data = {
            journeyType: tripType
        }

        Object.keys(payloadValues).forEach(rowKey => {
            const rowData = payloadValues[rowKey]
            Object.keys(rowData).forEach(fieldName => {
                const fieldValue = rowData[fieldName]

                if (fieldName === 'passengers') {
                    const { adults, children, infants, youth } = fieldValue

                    data['ADT'] = adults
                    data['CHD'] = children
                    data['INF'] = infants

                    // Youth for "LHR" flights
                    if (youth !== null) {
                        data['C13'] = youth
                    }
                }

                // Promo code => Not required
                if (fieldName === 'promo_code' && fieldValue) {
                    data['promoCode'] = fieldValue
                }

                // Round Trip
                if (tripType === 'round-trip') {
                    if (fieldName === 'location_from') {
                        const { code } = fieldValue

                        data['origin'] = code
                        data['destination1'] = code
                    }

                    if (fieldName === 'location_to') {
                        const { code } = fieldValue

                        data['destination'] = code
                        data['origin1'] = code
                    }

                    if (fieldName === 'date') {
                        const startDate = moment(fieldValue[0])
                        const endDate = moment(fieldValue[1])

                        data['date'] = startDate.format('MM-DD-YYYY')
                        data['date1'] = endDate.format('MM-DD-YYYY')
                    }
                } else if (tripType === 'one-way') {
                    if (fieldName === 'location_from') {
                        const { code } = fieldValue
                        data['origin'] = code
                    }

                    if (fieldName === 'location_to') {
                        const { code } = fieldValue
                        data['destination'] = code
                    }

                    if (fieldName === 'date') {
                        const date = moment(fieldValue[0])
                        data['date'] = date.format('MM-DD-YYYY')
                    }
                } else {
                    const key = rowKey.split('_')[1]
                    const suffix = key === '0' ? '' : key

                    if (fieldName === 'location_from') {
                        const { code } = fieldValue
                        data[`origin${suffix}`] = code
                    }

                    if (fieldName === 'location_to') {
                        const { code } = fieldValue
                        data[`destination${suffix}`] = code
                    }

                    if (fieldName === 'date') {
                        const date = moment(fieldValue[0])
                        data[`date${suffix}`] = date.format('MM-DD-YYYY')
                    }
                }
            })
        })

        return {
            ...this.defaultValues,
            ...data,
        }
    }

    /**
     * Locale formatter
     */
    localeFormatter(language = null, country = null) {
        if (language === 'en' && country === 'us') {
            return 'en-US'
        } else {
            const formatter = {
                'sr_Latn': 'sr-LATN',
                'sr_Cyrl': 'sr-CYRL',
                'en': 'en-GB',
                'de': 'de-DE',
                'fr': 'fr-FR',
                'ru': 'ru-RU',
                'el': 'el-GR',
                'sv': 'sv-SE',
                'it': 'it-IT',
                'nl': 'nl-NL',
                'es': 'es-ES',
                'tr': 'tr-TR',
            }

            if (formatter.hasOwnProperty(language)) {
                return formatter[language]
            } else {
                return formatter['en']
            }
        }
    }

    /**
     * Point of sale formatter
     * @param {String} country
     */
    pointOfSaleFormatter(country = '') {
        return country.toUpperCase()
    }
}

export default DeepLinking
