import moment from 'moment'
import flatpickr from 'flatpickr'

class TimeRangeInput {
    constructor(domElement = null) {
        this.input = $(domElement)
        if(!this.input) {
            console.error('Please provide proper DOM element for TimeRangeInput class')
            return
        }

        // Set picker localization
        const {
            datePicker
        } = window.localeData
        this.locale = datePicker

        this.fpInstanceStart = null
        this.fpInstanceEnd = null

        this.inputId = this.input.attr('id')
        this.container = this.input.closest('.form-floating')

        const { fromTitle, toTitle, confirm } = this.container.data()
        this.translations = {
            confirm,
            start: fromTitle,
            end: toTitle
        }

        this.startInput = this.container.find('.start-time-input')
        this.endInput = this.container.find('.end-time-input')

        this.clearButton = this.container.find('.btn-close')

        this.startValue = null
        this.endValue = null

        this.init()
        this.addEventListeners()
    }

    addEventListeners() {
        $(`.time-picker-submit[data-connected-input="${this.inputId}"]`).on('click touch', event => this.handleConfirm(event))

        this.clearButton.on('click touch', () => this.handleClear())
    }

    destroy() {
        $(`.time-picker-submit[data-connected-input="${this.inputId}"]`).off()
        this.clearButton.off()

        this.fpInstanceStart.destroy()
        this.fpInstanceEnd.destroy()

        this.fpInstanceStart = null
        this.fpInstanceEnd = null
    }

    init() {
        this.fpInstanceStart = new flatpickr(this.startInput[0], {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            disableMobile: true,
            onReady: (selectedDates, dateStr, instance) => {
                this.setCalendarMarkup(instance, 'start')
            },
            onClose: () => this.handleClose('start')
        })

        this.fpInstanceEnd = new flatpickr(this.endInput[0], {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            disableMobile: true,
            onReady: (selectedDates, dateStr, instance) => {
                this.setCalendarMarkup(instance, 'end')
            },
            onClose: () => this.handleClose('end')
        })

        const selectedValue = this.input.val()
        if (selectedValue && selectedValue.includes('-')) {
            const values = selectedValue.split('-')
            if(values.length == 2) {
                this.clearButton.removeClass('d-none')

                const startValue = values[0].trim()
                const endValue = values[1].trim()

                const startDate = moment(startValue, 'HH:mm').toDate()
                const endDate = moment(endValue, 'HH:mm').toDate()

                this.fpInstanceStart.setDate([startDate])
                this.fpInstanceEnd.setDate([endDate])
            }
        }
    }

    handleConfirm(event) {
        const button = $(event.currentTarget)
        const { fpInstance } = button.data()

        if (fpInstance === 'start') {
            this.fpInstanceStart.close()
            setTimeout(() => {
                this.fpInstanceEnd.open()
            }, 100)

            const { selectedDates } = this.fpInstanceStart
            if (selectedDates.length) {
                const time = moment(selectedDates[0]).format("HH:mm")
                this.startValue = time
            }
        } else {
            this.fpInstanceEnd.close()

            const { selectedDates } = this.fpInstanceEnd
            if (selectedDates.length) {
                const time = moment(selectedDates[0]).format("HH:mm")
                this.endValue = time
            }
        }

        this.clearButton.removeClass('d-none')
        this.input.val(`${this.startValue || ''} - ${this.endValue || ''}`)
    }

    handleClear() {
        this.fpInstanceStart.clear()
        this.fpInstanceEnd.clear()

        this.startValue = null
        this.endValue = null

        this.input.val('')
        this.clearButton.addClass('d-none')
    }

    handleClose(type = 'start') {
        setTimeout(() => {
            if (type === 'end' && !this.endValue) {
                this.handleClear()
            }
        }, 100)
    }

    setCalendarMarkup(calendarInstance = null, type = 'start') {
        if(calendarInstance) {
            const { calendarContainer } = calendarInstance

            const calendarTitleMarkup = `
                <h6 class="title small p-1 mb-0">
                    ${this.translations[type]}
                </h6>
            `

            const submitButtonMarkup = `
                <a
                    href="javascript:;"
                    class="time-picker-submit px-4 d-flex"
                    data-fp-instance="${type}"
                    title="${this.translations.confirm}"
                    data-connected-input="${this.input.attr('id')}"
                >
                    <img src="/static/icons/status/Checkmark_selected.svg">
                </a>
            `

            $(calendarContainer).addClass('flatpicker-title').prepend(calendarTitleMarkup)
            $(calendarContainer).find('.flatpickr-time').append(submitButtonMarkup)
        }
    }
}

export default TimeRangeInput
