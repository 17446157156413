import axios from "axios"
import { updateUrlParameter } from 'ContentBundle/js/frontend/utils/qS'
import LocationInput from "../bricks/booking/main-booking-panel/parts/fields/LocationInput"

class DesiredLocationForm {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            return
        }

        this.form = this.container.find('form')
        this.resultContainer = this.container.find('.desired-locations-result')

        this.locationSelect = null
        const { index, selectIcon } = this.form.data()
        const { options } = window[`desired_location_${index}_data`]
        this.selectIcon = selectIcon
        this.options = options

        const {
            country,
            language,
            displayRegions,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.localizedRegions = Object.keys(displayRegions).reduce((acc, k) => {
            acc[k.toLowerCase()] = displayRegions[k]
            return acc
          }, {})

        this.init()
    }

    init() {
        this.initSelect()
        this.addEventListeners()
    }

    initSelect() {
        const locationSelect = this.form.find('.form-input[data-input-type="select"]')
        if (locationSelect.length) {
            this.destroyLocationSelect()
            new LocationInput(locationSelect[0], {options: this.options})
            this.locationSelect = locationSelect[0].selectize
            this.locationSelect.clear()

            const icon = document.createElement('span')
            icon.setAttribute('class', 'icon')
            icon.innerHTML = this.selectIcon
            this.locationSelect.$control.append(icon)
        }
    }

    addEventListeners() {
        this.form.on('submit', async (event) => {
            event.preventDefault()

            const button = this.form.find('button')
            const formData = new FormData(this.form[0])

            if (!this.locationSelect.getValue()) {
                return
            }

            button.addClass('requesting')

            try {
                // Append "ajax=true" as query param
                let requestUri = this.form[0].action
                requestUri = updateUrlParameter(requestUri, 'ajax', true)

                const response = await axios.post(requestUri, formData)

                this.resultContainer.empty().html(response.data)
                this.resultContainer.addClass('mt-7')

                button.removeClass('requesting')
            } catch (error) {
                button.removeClass('requesting')
                console.log(error)
                throw error
            }
        })
    }

    destroyLocationSelect() {
        if (this.locationSelect) {
            this.locationSelect.destroy()
            this.locationSelect = null
        }
    }
}

export default DesiredLocationForm