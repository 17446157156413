import axios from 'axios'
import Dropzone from 'dropzone'

// We will attach dropzone automatically
Dropzone.autoDiscover = false


/**
 * FileUpload class component
 */
class FileUploadInput {
    /**
     * Constructor
     * @param {HTMLElement} domElement
     * @param {Object} options
     */
    constructor(domElement = null, options = {
        action: null,
        endPoints: null,
        extraOptions: {}
    }) {
        this.fileUploadInput = $(domElement)
        this.options = options

        if(!this.fileUploadInput.length) {
            console.error('Please provide proper DOM element for FileUploadInput class')
            return
        }

        this.files = {}
        this.formbuilderInput = null
        this.dropzoneInstance = null

        this.inputContainer = this.fileUploadInput.closest('.form-floating')

        this.button = this.inputContainer.find('.custom-file-input button')
        this.dropzonePreviewElement = this.inputContainer.find('.dropzone-previews')

        const { fileIcon, deleteIcon } = this.dropzonePreviewElement.data()

        this.fileIcon = fileIcon
        this.deleteIcon = deleteIcon

        this.inputTopContainer = this.fileUploadInput.closest('.tab-pane').length
                ? this.fileUploadInput.closest('.tab-pane')
                : this.fileUploadInput.closest('form.formbuilder')

        this.submitButton = this.inputTopContainer.find('button[type="submit"]')

        this.storageField = null
        this.formbuilderInput = false
        this.suspendFileRemoval = false

        if(this.fileUploadInput.data('formbuilderInput')) {
            this.formbuilderInput = true

            const storageFieldId = `#${this.fileUploadInput.attr('id')}_data`
            this.storageField = this.inputTopContainer.find(storageFieldId)

            this.storageField.val('[]')
        }

        this.init()
        this.addEventListeners()
    }

    /**
     * Initialize method
     */
    init() {
        // Extra options for Formbuilder file input
        let dynamicInputOptions = {}
        const { engineOptions, fileTooBigMessage, maxFilesizeMessage } = this.fileUploadInput.data()
        if(engineOptions && this.formbuilderInput) {
            dynamicInputOptions = this.setDynamicFormBuilderOptions(engineOptions)
        }

        this.fileTooBigMessage = fileTooBigMessage
        this.maxFilesizeMessage = maxFilesizeMessage

        // Create Dropzone instance
        this.dropzoneInstance = new Dropzone(this.fileUploadInput[0], {
            url: this.options.action,
            autoProcessQueue: false,
            // maxFilesize: 2, // 2MB
            // "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
            dictFileTooBig: `${fileTooBigMessage} ({{filesize}}MiB). ${maxFilesizeMessage}: {{maxFilesize}}MiB.`,
            paramName: this.fileUploadInput[0].name,
            uploadMultiple: true,
            clickable: this.button[0],
            hiddenInputContainer: this.fileUploadInput.closest('.form-floating')[0],
            previewsContainer: this.dropzonePreviewElement[0],
            previewTemplate: this.dropzonePreview(),
            ...dynamicInputOptions,
            ...this.options.extraOptions
        })
    }

    /**
     * Attach event listeners on dropzone instance
     */
    addEventListeners() {

        this.dropzoneInstance.on('sending', (file, xhr, fd) => {
            // Here we should add some information about file before send
            // instead perform send in `addedfile` event listener, because
            // this event `addedfile` happen before validation
        });

        this.dropzoneInstance.on('addedfile', (file) => {
            if (!this.validateFileSize(file)) {
                // avoid case where invalid file is added to form which cause
                // that assertion NotBlank is not triggered
                this.dropzoneInstance.removeFile(file)
                return false
            }

            if (!Dropzone.isValidFile(file, this.dropzoneInstance.options.acceptedFiles)) {
                // Prevent adding and uploading file with not allowed extension
                this.dropzoneInstance.removeFile(file)
                return false;
            }

            const { maxFiles } = this.dropzoneInstance.options
            if(maxFiles) {
                const count = this.dropzoneInstance.files.length
                if(count > maxFiles) {
                    this.dropzoneInstance.removeFile(file)
                    return false
                }

                if(count === maxFiles) {
                    this.button.prop('disabled', true)
                }
            }

            this.dropzonePreviewElement.addClass('py-3')

            // Keep file uuid if provided
            // => Will be triggered when we have cloned file
            if (file.uuid) {
                file.upload.uuid = file.uuid
            }

            // Add file to store
            if (this.formbuilderInput) {
                const uuid = file.upload.uuid
                this.files[uuid] = file

                this.addUploadedFile(file)
                file.previewElement.setAttribute('data-uuid', uuid)
            }
        })

        this.dropzoneInstance.on('removedfile', (file) => {
            if (this.suspendFileRemoval) {
                return
            }

            const { maxFiles } = this.dropzoneInstance.options
            if(maxFiles) {
                const count = this.dropzoneInstance.files.length
                if(count < maxFiles) {
                    this.button.prop('disabled', false)
                }
            }

            if (!this.dropzoneInstance.files.length) {
                this.dropzonePreviewElement.removeClass('py-3')
            }

            // Remove uploaded file
            if (this.formbuilderInput && this.options.endPoints) {
                const uuid = file.upload.uuid
                delete this.files[uuid]

                if (file.status !== 'canceled') {
                    this.removeUploadedFile(file)
                }
            }

            // Remove errors
            /*
            if (!this.dropzoneInstance.files.length) {
                this.inputContainer.parent().find('.invalid-feedback.invalid-feedback-file').remove()
                this.inputContainer.removeClass('is-invalid')
            } else {
                let hasError = false
                this.dropzoneInstance.files.forEach((file) => {
                    if (file.status == 'error' || !file.accepted) {
                        hasError = true
                    }
                })

                if (!hasError) {
                    this.inputContainer.parent().find('.invalid-feedback.invalid-feedback-file').remove()
                    this.inputContainer.removeClass('is-invalid')
                }
            }*/
        })

        this.dropzoneInstance.on('error', (file, message) => {
            if (file.status == 'error' || !file.accepted) {
                const messageHTML = `
                    <div class="invalid-feedback invalid-feedback-file caption-medium mt-n2 ps-4">
                        ${message}
                    </div>
                `
                this.inputContainer.parent().find('.invalid-feedback.invalid-feedback-file').remove()
                this.inputContainer.addClass('is-invalid')
                this.inputContainer.parent().append(messageHTML)
            }
        })

        if (this.formbuilderInput) {
            /*
            // Crate && append generated file uuid
            this.dropzoneInstance.on('sending', (file, xhr, formData) => {
                this.submitButton.addClass('requesting').addClass('file-upload-disabled')
                formData.append('uuid', file.upload.uuid)
            })

            // Enable submit button
            this.dropzoneInstance.on('complete', () => {
                this.submitButton.removeClass('requesting').removeClass('file-upload-disabled')
            })

            this.dropzoneInstance.on('cancel', () => {
                this.submitButton.removeClass('requesting').removeClass('file-upload-disabled')
            })

            // Add file to storage field input values
            this.dropzoneInstance.on('success', (file, response) => {
                this.addToStorageField({
                    id: response.uuid,
                    fileName: response.fileName,
                })
            })
            */

            // Suspend file removal
            this.dropzoneInstance.on('reset', () => {
                this.suspendFileRemoval = false
            })
        }
    }

    /**
     * Uploaded file preview markup
     * @returns {String}
     */
    dropzonePreview() {
        return `
            <div class="dz-preview dz-file-preview my-2">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="dz-details d-flex align-items-center">
                        <div class="file-icon me-3">${this.fileIcon}</div>
                        <div class="dz-filename"><span data-dz-name></span></div>
                    </div>
                    <div data-dz-remove>${this.deleteIcon}</div>
                </div>
            </div>
        `
    }

    validateFileSize(file) {
        const { maxFilesize } = this.dropzoneInstance.options
        const maxFileSizeBytes = maxFilesize * 1024 * 1024
        let valid = true

        // If file size is bigger than allowed
        // change flag and trigger error that is already handled
        if (file.size > maxFileSizeBytes) {
            valid = false
            this.dropzoneInstance.emit('error', file, this.generateFileSizeErrorMessage(file))
        }

        return valid
    }

    generateFileSizeErrorMessage(file) {
        return this.dropzoneInstance.options.dictFileTooBig
          .replace("{{filesize}}", Math.round(file.size / 1024 / 10.24) / 100)
          .replace("{{maxFilesize}}", this.dropzoneInstance.options.maxFilesize)
    }

    /**
     * File size validation (Will be throwed by backend)
     */
    /*
    validateMaxFileSizes() {
        const { engineOptions } = this.fileUploadInput.data()
        if(engineOptions && this.formbuilderInput) {
            const { max_file_size } = engineOptions
            if(max_file_size) {
                const maxFileSize = max_file_size * 1024 * 1024

                let combineFileSize = 0
                this.dropzoneInstance.files.forEach(file => {
                    combineFileSize += file.size
                })

                if(maxFileSize < combineFileSize) {
                    this.inputContainer.addClass('is-invalid')
                    this.submitButton.addClass('file-error-disabled')

                    if (this.inputJSError.length) {
                        const { file_size_exceeded }  = this.inputJSError.data('msg')
                        this.inputJSError.html(file_size_exceeded)
                    }*
                } else {
                    this.inputContainer.removeClass('is-invalid')
                    this.submitButton.removeClass('file-error-disabled')

                    if (this.inputJSError.length) {
                        this.inputJSError.html('')
                    }*
                }
            }
        }
    }
    */

    /**
     * Map engine options provided by FormBuilder
     */
    setDynamicFormBuilderOptions(engineOptions = {}) {
        let options = {}

        // Url options for file upload ("file_add")
        if (this.options.endPoints) {
            /*
            options = {
                paramName: 'dmfData',
                url: `${this.options.endPoints['file_add']}?ajax=true`,
                autoProcessQueue: true,
                ...options,
            }*/
        }

        // Engine options
        const { allowed_extensions, item_limit, max_file_size, multiple } = engineOptions

        if(allowed_extensions !== undefined) {
            options = {
                ...options,
                acceptedFiles: allowed_extensions
            }
        }

        if(multiple !== undefined) {
            options = {
                ...options,
                uploadMultiple: multiple
            }
        }

        // "Empty" or "0" => no limits
        /*
        if(max_file_size) {
            options = {
                ...options,
                maxFilesize: max_file_size
            }
        }
        */

        if(item_limit !== undefined) {
            options = {
                ...options,
                maxFiles: item_limit
            }
        }

        return options
    }

    /**
     * Upload selected file via formbuilder upload route
     * @param {Object} file
     */
    async addUploadedFile(file) {
        this.submitButton.addClass('requesting').addClass('file-upload-disabled')

        try {
            const { disableApiActions } = file
            if (!disableApiActions) {
                const formData = new FormData()
                formData.append('uuid', file.upload.uuid)
                formData.append('dmfData', file)

                const requestUri = `${this.options.endPoints['file_add']}?ajax=true`
                const response = await axios.post(requestUri, formData)

                const { data } = response

                if (data && data.success) {
                    this.addToStorageField({
                        id: file.upload.uuid,
                        fileName: file.name,
                    })
                } else {
                    // Handle upload error message (eg. filesize)
                    this.addFileUploadError(file.upload.uuid)
                    delete this.files[file.upload.uuid]
                }
            } else {
                this.addToStorageField({
                    id: file.upload.uuid,
                    fileName: file.name,
                })
            }

            this.submitButton.removeClass('requesting').removeClass('file-upload-disabled')
        } catch(err) {
            this.submitButton.removeClass('requesting').removeClass('file-upload-disabled')

            // Handle upload error message (eg. filesize)
            this.addFileUploadError(file.upload.uuid)
            delete this.files[file.upload.uuid]

            console.error(err)
            throw err
        }
    }

    /**
     * Remove uploaded file action handler
     * @param {Object} file
     */
    async removeUploadedFile(file) {
        try {
            const requestUri = `${this.options.endPoints['file_delete']}/${file.upload.uuid}?ajax=true`
            await axios.delete(requestUri)

            this.removeFromStorageField({ id: file.upload.uuid })
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * Append file to "fileStorage" input values
     */
    addToStorageField({ id, fileName }) {
        if(this.storageField) {
            let value = this.storageField.val()

            let data = typeof value === 'string' && value !== ''
                ? JSON.parse(value)
                : []

            data.push({
                id,
                fileName
            })

            this.storageField.val(JSON.stringify(data))
        }
    }

    /**
     * Remove file from "fileStorage" input values
     */
    removeFromStorageField({ id }) {
        if(this.storageField) {
            let value = this.storageField.val()

            let data = typeof value === 'string' && value !== ''
                ? JSON.parse(value)
                : []

            data = data.filter((d) => d.id !== id)
            this.storageField.val(JSON.stringify(data))
        }
    }

    /**
     * Append error to file preview
     * @param {String} fileUUID
     */
    addFileUploadError(fileUUID = null) {
        const previewElement = this.dropzonePreviewElement.find(`.dz-file-preview[data-uuid="${fileUUID}"]`)
        if (previewElement.length) {
            previewElement.append(
                `
                    <div class="invalid-feedback invalid-feedback-file caption-medium d-block mt-2 ps-6">
                        ${this.fileTooBigMessage}
                    </div>
                `
            )
        }
    }

    /**
     * Fetch Dropzone instance
     * @returns {Dropzone}
     */
    getInstance() {
        if (this.dropzoneInstance) {
            return this.dropzoneInstance
        } else {
            return null
        }
    }

    /**
     * Get uploaded blob files
     * @returns {Object}
     */
    getFiles() {
        return this.files
    }

    /**
     * Destroy dropzone instance
     */
    destroy() {
        if (this.dropzoneInstance) {
            this.dropzoneInstance.destroy()
        }
    }
}

export default FileUploadInput
