let deferredPrompt
let calledPrompt = JSON.parse(localStorage.getItem('calledPrompt')) ?? false

window.addEventListener('beforeinstallprompt', (event) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault()

    deferredPrompt = event
})

// Touch so that it triggers only on touch based devices
window.addEventListener('touchstart', () => {
    if (!calledPrompt && deferredPrompt) {
        // Try to show prompt every second until it succeeds
        setTimeout(() => {
            try {
                deferredPrompt.prompt()
                localStorage.setItem('calledPrompt', JSON.stringify(true))
            } catch (error) {
                calledPrompt = false
                console.error(error)
            }
        }, 1000)
        calledPrompt = true
    }
})