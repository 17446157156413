import axios from 'axios'
import LocationInput from '../booking/main-booking-panel/parts/fields/LocationInput'
import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

/**
 * CargoAgents class component
 */

class CargoAgents {
    /**
     * CargoAgents class constructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.container = $(domElement)
        if(!this.container.length) {
            console.error('Please provide proper DOM element for CargoAgents class')
            return
        }

        // Fetch data
        const { index } = this.container.data()
        const { options, routes } = window[`cargo_agents_${index}_data`]

        this.routes = routes
        this.options = options

        this.filters = {}

        this.init()
        this.setEventListeners()
    }

    /**
     * Destructor
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Init method
     */
    init() {
        this.container.find('.cargo-agents--wrapper .form-input[data-input-type="location-input"]').each((_, input) => {
            new LocationInput(input, {
                options: this.options.locations,
                optionsRoute: null,
                onChange: data => this.handleLocationChange(data)
            })
        })
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click touch', '.cargo-agents--wrapper .btn-search', this.handleButtonSubmit.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click touch')
    }

    /**
     * Location change handler
     * @param {Object} data
     */
    handleLocationChange(data = {}) {
        const {
            type,
            selected,
        } = data

        this.filters = {
            ...this.filters,
            [type]: selected ? selected.id : null,
        }
    }

    /**
     * Set markup to list
     * @param {String} markup
     */
    setListContent(markup = '') {
        this.container.find('.cargo-agents--list-container').html(markup)
    }

    /**
     * Search button submit
     * @param {Object} event
     */
    async handleButtonSubmit(event) {
        const $button = $(event.currentTarget)

        try {
            $button.addClass('is-requesting')

            const requestUri = this.createRequestUri()
            const response = await axios.get(requestUri)
            const { data } = response

            this.setListContent(data)

            $button.removeClass('is-requesting')
        } catch(err) {
            $button.removeClass('is-requesting')
            console.error(err)
            throw err
        }
    }

    createRequestUri() {
        const querries = createUrlParamsFromObject({...this.filters, locale: window.localeData ? window.localeData.language : null})
        return `${this.routes.agents}?${querries}`
    }
}

export default CargoAgents
