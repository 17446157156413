import { Tab } from 'bootstrap'

class Header {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper DOM element for Header class')
            return
        }

        /**
         * @type UserAuth
         */
        this.UserAuth = window.UserAuth
        this.homePagePath = this.container.find('a.logo-container').attr('href')

        this.languageSelects = this.container.find('.header-languages-select')
        this.countrySelects = this.container.find('.header-countries-select')

        this.actionsContainer = this.container.find('.actions-container')
        this.loggedTemplate = this.actionsContainer.find('template.logged-template').html()
        this.notLoggedTemplate = this.actionsContainer.find('template.not-logged-template').html()

        this.mobileActionsContainer = this.container.find('.mobile-actions-container')
        this.mobileLoggedTemplate = this.mobileActionsContainer.find('template.logged-template').html()
        this.mobileNotLoggedTemplate = this.mobileActionsContainer.find('template.not-logged-template').html()

        this.setEventListeners()
        this.initSelects()
        this.setLastActiveTab()

        this.setMobileMenuHeight()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click touch', '.header-hamburger', () => {
            const $navigation = this.container.find('.header-navigation')

            if($navigation.hasClass('menu-open')) {
                $navigation.removeClass('menu-open')
                $('body').removeClass('disable-scroll')
            } else {
                $navigation.addClass('menu-open')
                $('body').addClass('disable-scroll')

                this.setMobileMenuHeight()
            }
        })

        this.container.on('click touch', '.accordion-link', function() {
            if ($(this).attr('target') == '_blank') {
                window.open($(this).attr('href'), '_blank')

            } else {
                window.location.href = $(this).attr('href')
            }
        })

        this.container.on('click touch', '.mobile-actions-container .btn-logout', this.handleLogout.bind(this))

        $(window).on('resize.hero-banner', () => this.setMobileMenuHeight())

        this.container.on('show.bs.tab', function(e) {
            localStorage.setItem('activeHeaderTabId', $(e.target).attr('id'))
        })

        // Listen for Auth changes
        this.UserAuth.on('onAuthChange', () => {
            this.updateUserData()
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
        this.container.off('show.bs.tab')
    }

    initSelects() {
        this.initLanguageSelect(this.countrySelects)
        this.initCountrySelect(this.languageSelects)
    }

    setLastActiveTab() {
        let activeHeaderTabId = localStorage.getItem('activeHeaderTabId')

        if (activeHeaderTabId) {
            let lastActiveTabButton = $(`#${activeHeaderTabId}`)

            if (lastActiveTabButton.length) {
                let lastActiveTab = new Tab(lastActiveTabButton)
                lastActiveTab.show()
            }
        }
    }

    initLanguageSelect(countrySelect) {
        this.languageSelects.each(function() {
            $(this).selectize({
                maxItems: 1,
                onChange: val => {
                    window.location.href = val
                },
                onDropdownOpen: () => {
                    countrySelect.each(function() {
                        this.selectize.close()
                    })
                }
            })
        })
    }

    initCountrySelect(languageSelect) {
        this.countrySelects.each(function() {
            const isMobile = $(this).hasClass('header-countries-select-mobile')

            $(this).selectize({
                maxItems: 1,
                openOnFocus: isMobile,
                onChange: val => {
                    window.location.href = val
                },
                onDropdownOpen: () => {
                    languageSelect.each(function() {
                        this.selectize.close()
                    })
                },
                render: {
                    option: function(data, escape) {
                        let option = ''

                        if (data.disabled) {
                            option = `
                                <div class="option">
                                    <span>${escape(data.text)}</span>
                                </div>`

                        } else {
                            option = `
                                <div class="option">
                                    <span class="img-container">${data.icon}</span>
                                    <span class="body-1">${escape(data.text)}</span>
                                </div>`
                        }

                        return option
                    },
                    item: function(data, escape) {
                        let item = ''

                        if (isMobile) {
                            item = `<div class="item">${escape(data.name)}</div>`

                        } else {
                            item = `<div class="item ${data.shorthand == 'global' ? 'global-icon' : ''}">${data.icon}</div>`
                        }
                        return item
                    }
                }
            })
        })
    }

    /**
     * Handler for logout button click (mobile)
     * @param {Object} event
     */
    async handleLogout(event) {
        const $button = $(event.currentTarget)

        if ($button.hasClass('is-requesting')) {
            return
        }

        $button.addClass('is-requesting')

        try {
            await this.UserAuth.logoutUser()
            this.redirectToHome()

            return
        } catch(err) {
            $button.removeClass('is-requesting')
            console.error(err)
            throw err
        }
    }

    /**
     * Update user data in header
     */
    updateUserData() {
        const userData = localStorage.getItem('user_data')
            ? JSON.parse(localStorage.getItem('user_data'))
            : null

        if (userData) {
            const userName = userData.firstname || userData.email

            const profileImage = userData.photoUrl
                ? `<img src="${userData.photoUrl}">`
                : userName.charAt(0).toUpperCase()

            // Set dynamic contents to desktop template
            const $template = $('<div />').append(this.loggedTemplate)
            $template.find('.btn-profile').append(profileImage)

            // Set dynamic content to mobile template
            const $mobileTemplate = $('<div />').append(this.mobileLoggedTemplate)
            $mobileTemplate.find('.btn-profile').append(profileImage)
            $mobileTemplate.find('.user-name-holder').text(userName)

            // Set content
            this.actionsContainer.html('').append($template)
            this.mobileActionsContainer.html('').append($mobileTemplate)
        } else {
            this.actionsContainer.html(this.notLoggedTemplate)
            this.mobileActionsContainer.html(this.mobileNotLoggedTemplate)
        }

        this.setMobileMenuHeight()
    }

    /**
     * Redirect to homepage
     * */
    redirectToHome() {
        if (this.homePagePath) {
            window.location.href = this.homePagePath
        } else {
            console.error('Homepage path can not be found')
        }
    }

    /**
     * Calculate & set height to mobile navigation
     */
    setMobileMenuHeight() {
        const mobileMenu = this.container.find('.mobile-navigation')
        const headerHeight = $('header .header-navigation').height()

        // Set full menu height
        const calculatedHeight = window.innerHeight - headerHeight
        mobileMenu.css('maxHeight', `${calculatedHeight}px`)

        // Now calculate & set links inner container
        const linksContainer = mobileMenu.find('.links-container')
        const actionsHeight = mobileMenu.find('.mobile-actions-container').outerHeight(true)
        const selectsHeight = mobileMenu.find('.mobile-selects-container').outerHeight(true)

        const linksHeight = calculatedHeight - actionsHeight - selectsHeight
        linksContainer.css('maxHeight', `${linksHeight}px`)
    }
}

export default Header