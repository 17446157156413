/**
 * PhoneNumber input class component
 */
import XRegExp from 'xregexp'

class PhoneNumberInput {
    constructor(domElement = null, {
        initialCountry = null,
        localizedCountries = {},
        options = {},
    }) {
        this.input = $(domElement)
        if(!this.input.length) {
            console.error('Please provide propper DOM input element for PhoneNumberInput class')
            return
        }

        this.itiInstance = null
        this.options = options
        this.initialCountry = initialCountry
        this.localizedCountries = localizedCountries

        this.preferedCountries = []
        this.excludeCountries = ['xk']

        this.fieldOptions = {
            nationalMode: false,
            separateDialCode: true, // User won't be able to change it other than with selection
            autoInsertDialCode: true,
            preventInvalidDialCodes: false,
            preferredCountries: this.preferedCountries,
            excludeCountries: this.excludeCountries,
            utilsScript: '/static/js/intl-tel-input-utils.js?1687509211722'
        }

        this.topParent = this.input.closest('.form-floating')

        this.init()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()

        if(this.itiInstance) {
            const wrapper = this.input.parent()
            const label = wrapper.find('label')

            wrapper.removeClass('form-floating')
            label.insertAfter(wrapper)

            this.itiInstance.destroy()
            this.itiInstance = null
        }
    }

    init() {
        this.setConfigOptions()

        const label = this.input.parent().find('label')

        // Initialize + get iti instance
        this.input.intlTelInput(this.fieldOptions)
        this.itiInstance = window.intlTelInputGlobals.getInstance(this.input[0])

        if(label.length) {
            label.insertAfter(this.input)
        }

        // Set class so label can be floated
        this.input.parent().addClass('form-floating')

        // Attach extra event listeners
        this.setEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.input.on('keydown', event => {
            // Enable backspace
            if (event.keyCode === 8) {
                return true
            }

            // Enable space
            if (event.keyCode === 32) {
                return true
            }

            // "+" on first place
            const value = this.input.val()
            if(!value.length && event.key === '+') {
                return true
            }

            // Regex
            const regex = new XRegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)
            if (!regex.test(event.key)) {
                event.preventDefault()
                event.stopPropagation()

                return false
            }
        })
    }

    /**
     * Remove event listeners
     */
    removeEventListeners() {
        this.input.off('keydown')
    }

    /**
     * Set options for intl-plugin
     */
    setConfigOptions() {
        // Selected country from header
        if (this.initialCountry) {
            this.fieldOptions = {
                ...this.fieldOptions,
                initialCountry: this.initialCountry,
            }
        }

        // Localized options
        if (Object.keys(this.localizedCountries).length) {
            this.fieldOptions = {
                ...this.fieldOptions,
                localizedCountries: this.localizedCountries,
            }
        }

        this.fieldOptions = {
            ...this.fieldOptions,
            ...this.options // Override with extra options provided
        }
    }

    /**
     * Value getter
     * @returns {Object}
     */
    getValue() {
        let number = this.itiInstance.getNumber()
        const countryData = this.itiInstance.getSelectedCountryData()

        number = number.replace(`+${countryData.dialCode}`, '')

        const areaCode = countryData.dialCode ? `+${countryData.dialCode}` : null
        const countryCode = countryData.iso2 ? countryData.iso2.toUpperCase() : null

        return {
            number,
            areaCode,
            countryCode,
        }
    }

    /**
     * Value setter
     * @param {String} value
     */
    setValue(value = null) {
        this.itiInstance.setNumber(value)
    }

    /**
     * Clear value method
     */
    clearValue() {
        this.itiInstance.setNumber('')
        this.itiInstance.setCountry(this.initialCountry)
    }

    /**
     * Validate field function
     * @returns {Object}
     */
    validate() {
        let isValid
        const values = this.getValue()
        const { number } = values

        if (!number) {
            this.input.addClass('is-invalid')
            this.topParent.addClass('is-invalid')

            isValid = false
        } else {
            this.input.removeClass('is-invalid')
            this.topParent.removeClass('is-invalid')

            isValid = true
        }

        return { isValid }
    }
}

export default PhoneNumberInput
