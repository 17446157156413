import Forms from '../../index'
import Preview from './Preview'
import FlightInfo from './FlightInfo'
import BankAccount from './BankAccount'
import PersonalData from './PersonalData'

import { Offcanvas } from 'bootstrap'

class ComplaintForm extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.topCategory = null
        this.subCategory = null
        this.selectedCategoryId = null

        this.howYouApplyValue = null
        this.forWhoYouApplyValue = null

        // Offcanvas
        this.drawerContainer = this.form.closest('.offcanvas')
        this.drawer = new Offcanvas(this.drawerContainer[0], {
            backdrop: 'static' // Outside click won't close drawer
        })

        this.bankAccountContainer = this.form.find('.bank-account--container')
        this.BankAccount = new BankAccount(this.bankAccountContainer[0])

        this.PersonalData = new PersonalData(this.form[0])
        this.FlightInfo = new FlightInfo(this.form[0])

        this.previewStepContainer = this.form.find('.tab-pane.preview-step')
        this.Preview = new Preview(this.form)

        this.form.find('.sub-category-input').val('')

        this.setInitialValues()
        this.setEventListeners()
        this.setOnCloseListener()
    }

    /**
     * Destructor
     */
    destroy() {
        this.BankAccount.destroy()
        this.BankAccount = null

        this.Preview.destroy()
        this.Preview = null

        this.FlightInfo.destroy()
        this.FlightInfo = null

        this.PersonalData.destroy()
        this.PersonalData = null

        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.form.on('click touch', '.btn-back', () => this.toPreviousStep())

        this.form.on('click touch', '.category-step .category-button', this.handleCategoryChange.bind(this))
        this.form.on('click touch', '.sub-category-step .category-choice-button', this.handleSubCategoryChange.bind(this))

        this.form.on('change', '.personal-data-step .how-you-apply-radio-input', this.handleHowYouApplyChange.bind(this))
        this.form.on('change', '.personal-data-step .for-who-you-apply-radio-input', this.handleForWhoYouApplyChange.bind(this))
    }

    /**
     * On close event listeners
     */
    setOnCloseListener() {
        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            this.step = 1

            this.resetFormValues()

            this.form.find('.category-step .category-button').removeClass('active')
            this.form.find('.sub-category-step .category-choice-button').removeClass('active')
            this.form.find('.sub-category-step .sub-category-input').val('')

            this.FlightInfo.resetFields()
            this.PersonalData.setSubmitButtonState()
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.form.off('click touch')
        this.form.off('change')
    }

    /**
     * Set form initial values
     */
    setInitialValues() {
        // Fetch charter value
        this.isCharterFlight = this.FlightInfo.isCharterFlight()

        // Personal data step radio buttons logic
        const $step3 = this.form.find('.personal-data-step')

        const $howInput = $step3.find('.how-you-apply-radio-input:checked')
        const $forWhoInput = $step3.find('.for-who-you-apply-radio-input:checked')

        this.howYouApplyValue = $howInput.val()
        this.forWhoYouApplyValue = $forWhoInput.val()

        this.setYourInfoFileFields()
    }

    /**
     * Parent category select handler
     * @param {Object} event
     */
    handleCategoryChange(event) {
        const $button = $(event.currentTarget)
        const { categoryName } = $button.data()

        const $categoryStep = this.form.find('.category-step')
        $categoryStep.find('.category-button').removeClass('active')

        $button.addClass('active')
        const $subCategoryStep = this.form.find('.sub-category-step')

        const categoryTitle = $button.attr('title')
        this.topCategory = categoryTitle

        $subCategoryStep.find('.selected-category-title').text(categoryTitle)
        $subCategoryStep.find('.category-choices').addClass('d-none')
        $subCategoryStep.find(`.category-choices[data-category="${categoryName}"]`).removeClass('d-none')

        this.toNextStep()
    }

    /**
     * SubCategory select handler
     * @param {Object} event
     */
    handleSubCategoryChange(event) {
        const $button = $(event.currentTarget)
        const { value, name } = $button.data()

        this.subCategory = name
        this.selectedCategoryId = value

        const $subCategoryStep = this.form.find('.sub-category-step')

        // Set proper active class
        $subCategoryStep.find('.category-choice-button').removeClass('active')
        $button.addClass('active')

        // Set value to hidden input
        $subCategoryStep.find('.sub-category-input').val(value)

        this.setFormNavTitle()
        this.setDocumentationStepFields()

        this.toNextStep()
    }

    /**
     * "How you apply" change handler
     * @param {Object} event
     */
    handleHowYouApplyChange(event) {
        const $input = $(event.currentTarget)
        const $step3 = this.form.find('.personal-data-step')

        const $forWhoInput = $step3.find('.for-who-you-apply-radio-input')

        const $myselfRadio = $step3.find('.for-who-you-apply-radio-input[value="myself"]')
        const $myselfRadioLabel = $myselfRadio.next('label')

        const selectedValue = $input.val()
        if (selectedValue === 'as_a_person') {
            $myselfRadio.removeClass('d-none')
            $myselfRadioLabel.removeClass('d-none')
        } else {
            $myselfRadio.addClass('d-none')
            $myselfRadioLabel.addClass('d-none')

            if ($forWhoInput.val() === 'myself') {
                $step3.find('.for-who-you-apply-radio-input[value="adult"]').prop('checked', true)
                $forWhoInput.trigger('change')
            }
        }

        this.howYouApplyValue = selectedValue

        this.setYourInfoFileFields()
    }

    /**
     * "For who you apply" change handler
     * @param {Object} event
     */
    handleForWhoYouApplyChange(event) {
        const $step3 = this.form.find('.personal-data-step')
        const $input = $step3.find('.for-who-you-apply-radio-input:checked')

        const selectedValue = $input.val()

        this.forWhoYouApplyValue = selectedValue

        this.setYourInfoFileFields()
    }

    /**
     * Set "Your info" file fields by radio button combinations
     */
    setYourInfoFileFields() {
        const $step3 = this.form.find('.personal-data-step')

        // "Myself radio"
        const $myselfRadio = $step3.find('.for-who-you-apply-radio-input[value="myself"]')
        const $myselfRadioLabel = $myselfRadio.next('label')

        if(this.howYouApplyValue) {
            if (this.howYouApplyValue === 'as_a_person') {
                $myselfRadio.removeClass('d-none')
                $myselfRadioLabel.removeClass('d-none')
            } else {
                $myselfRadio.addClass('d-none')
                $myselfRadioLabel.addClass('d-none')
            }
        }

        // Dynamic file fields
        $step3.find('.file-fields-container').each((_, item) => {
            const $field = $(item)
            const { name } = $field.data()

            if(name === 'travelers_consent_certified_by_a_notary') {
                if (this.howYouApplyValue === 'as_a_person' && this.forWhoYouApplyValue === 'adult') {
                    $field.removeClass('d-none')
                } else {
                    $field.addClass('d-none')
                }
            }

            if(name === 'birth_certificate') {
                if (this.howYouApplyValue === 'as_a_person' && this.forWhoYouApplyValue === 'a_minor') {
                    $field.removeClass('d-none')
                } else {
                    $field.addClass('d-none')
                }
            }

            if(name === 'power_of_attorney') {
                if (
                    this.howYouApplyValue === 'as_company' &&
                    (this.forWhoYouApplyValue === 'adult' || this.forWhoYouApplyValue === 'a_minor')
                ) {
                    $field.removeClass('d-none')
                } else {
                    $field.addClass('d-none')
                }
            }
        })

        // Authorized person & party
        this.setAuthorizedFields()
    }

    /**
     * Set navigation title via selected top category & category
     */
    setFormNavTitle() {
        const navTitle = `${this.topCategory}/${this.subCategory}`
        this.form.find('.nav-subtitle').text(navTitle)
    }

    /**
     * Set behaviour of "Authorized" fields
     */
    setAuthorizedFields() {
        const $personalDataStep = this.form.find('.personal-data-step')
        const $authorizedPersonContainer = $personalDataStep.find('.authorized-person-container')

        if ($authorizedPersonContainer.length == 0) {
            return
        }

        // Title & labels
        const $authorizedTitle = $authorizedPersonContainer.find('.step-subtitle')
        const $authorizedField = $authorizedPersonContainer.find('.form-control')
        const $authorizedLabel = $authorizedPersonContainer.find('.form-label')

        const { authorizedPerson, authorizedParty } = $authorizedPersonContainer.data('labels')

        if (this.howYouApplyValue === 'as_a_person') {
            $authorizedTitle.text(authorizedPerson.title)
            $authorizedLabel.text(authorizedPerson.label)
            $authorizedField.attr('placeholder', authorizedPerson.label)
        } else {
            $authorizedTitle.text(authorizedParty.title)
            $authorizedLabel.text(authorizedParty.label)
            $authorizedField.attr('placeholder', authorizedParty.label)
        }

        // Field visibility
        if (
            this.howYouApplyValue === 'as_a_person' && this.forWhoYouApplyValue === 'myself'
        ) {
            $authorizedPersonContainer.addClass('d-none')
        } else {
            $authorizedPersonContainer.removeClass('d-none')
        }
    }

    /**
     * Set visible fields via selected category Id
     */
    setDocumentationStepFields() {
        const $step5 = this.form.find('.documentation-step')
        const { fieldsMapping } = $step5.data()

        $step5.find('.field-container').each((_, item) => {
            const $field = $(item)
            const { name } = $field.data()

            const categoryIds = fieldsMapping[name] || []
            if(categoryIds.includes(this.selectedCategoryId)) {
                $field.removeClass('d-none')
            } else {
                $field.addClass('d-none')
            }
        })
    }

    /**
     * Re-use method
     */
    afterSubmit() {
        this.setFormNavTitle()
        this.setInitialValues()

        this.handleFlightInfoStepSubmission()

        // Set documents logic
        this.setDocumentationStepFields()

        this.FlightInfo.setOptions()

        this.PersonalData.setSubmitButtonState()

        // Re-init bank account
        this.BankAccount.destroy()

        this.bankAccountContainer = this.form.find('.bank-account--container')
        this.BankAccount = new BankAccount(this.bankAccountContainer[0])

        this.Preview.setContent()
    }

    toNextStep() {
        this.step = this.step + 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        /*
        if ($step.hasClass('preview-step')) {
            this.Preview.setContent()
        }

        if ($step.hasClass('documentation-step')) {
            this.setDocumentationStepFields()
        }
        */

        this.form.closest('.offcanvas-body').animate({scrollTop: 0}, 500)

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
            this.setInitialValues()
        }, 175)
    }

    toPreviousStep() {
        this.step = this.step - 1
        const $step = this.form.find(`.tab-pane[data-tab="step-${this.step}"]`)

        this.form.find('.tab-pane').removeClass('active show')

        $step.find('.invalid-feedback').remove()
        $step.find('.is-invalid').removeClass('is-invalid')

        this.form.closest('.offcanvas-body').animate({scrollTop: 0}, 500)

        $step.addClass('active')
        setTimeout(() => {
            $step.addClass('show')
            this.setInitialValues()
        }, 125)
    }

    /**
     * Separated logic for flight info (1st step)
     */
    handleFlightInfoStepSubmission() {
        if (this.step === 1 && this.isCharterFlight) {
            // Set category directly
            if (this.validateStep()) {
                const $categoryStep = this.form.find('.category-step')
                $categoryStep.find('.category-button').removeClass('active')

                const categoryName = 'Baggage'
                const $button = $categoryStep.find(`.category-button[data-category-name="${categoryName}"]`)

                $button.addClass('active')
                const $subCategoryStep = this.form.find('.sub-category-step')

                const categoryTitle = $button.attr('title')
                this.topCategory = categoryTitle

                $subCategoryStep.find('.selected-category-title').text(categoryTitle)
                $subCategoryStep.find('.category-choices').addClass('d-none')
                $subCategoryStep.find('.category-choice-button').removeClass('active')
                $subCategoryStep.find(`.category-choices[data-category="${categoryName}"]`).removeClass('d-none')

                $subCategoryStep.find('.charter-flight-text-container').removeClass('d-none')

                $subCategoryStep.find('.sub-category-input').val('')

                this.toStep(3)
            }
        } else if (this.step === 1) {
            // Classic flow
            if (this.validateStep()) {
                this.toNextStep()
            }

            const $categoryStep = this.form.find('.category-step')
            $categoryStep.find('.category-button').removeClass('active')

            const $subCategoryStep = this.form.find('.sub-category-step')
            $subCategoryStep.find('.sub-category-input').val('')
            $subCategoryStep.find('.category-choice-button').removeClass('active')
            $subCategoryStep.find('.charter-flight-text-container').addClass('d-none')
        }
    }

    /**
     * Reset values on form
     */
    resetFormValues() {
        this.form.removeClass('d-none')
        this.form.find('.alert').remove()
        this.form.closest('.offcanvas-body').find('.template-content').remove()
        this.form.find('.tab-pane').removeClass('active show')
        this.form.find(`.tab-pane[data-tab="step-${this.step}"]`).addClass('active show')
        this.form.find('.visible-fields input:not([type="radio"]), .visible-fields textarea').val('')

        this.form.find('.visible-fields input[type="radio"]').prop('checked', false)
        this.form.find('.visible-fields input[type="checkbox"]').prop('checked', false)

        this.form.find('.is-invalid').removeClass('is-invalid')
        this.form.find('.invalid-feedback').remove()

        this.form.find('.how-you-apply-radio-input[value="as_a_person"]').prop('checked', true)
        this.form.find('.for-who-you-apply-radio-input[value="myself"]').prop('checked', true)
        this.setInitialValues()

        this.initFields(true)
    }
}

export default ComplaintForm
