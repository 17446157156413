import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class IPortCheckIn {
    constructor(baseUrl = null) {
        this.baseUrl = baseUrl

        const {
            language,
        } = window.localeData

        this.userLanguage = language
        this.userLocale = this.localeFormatter(this.userLanguage)

        // Since locale is part of uri, replace it
        this.baseUrl = this.baseUrl.replace('__locale__', this.userLocale)

        this.defaults = {}
    }

    engage(payload = {}, newWindow = true) {
        const data = this.formatPayload(payload)
        const querries = createUrlParamsFromObject(data)

        const redirectUrl = `${this.baseUrl}?${querries}`
        if (newWindow) {
            window.open(redirectUrl, '_blank')
        } else {
            window.open(redirectUrl, '_self')
        }
    }

    /**
     * Locale formatter
     * @param {String} language
     */
    localeFormatter(language = null) {
        const formatter = {
            'sr': 'sr',
            'sr_Cyrl': 'sr',
            'sr_Latn': 'sr',
        }

        if (formatter.hasOwnProperty(language)) {
            return formatter[language]
        } else {
            return 'en'
        }
    }

    formatPayload(payload = {}) {
        const data = {}
        Object.keys(payload).forEach(inputName => {
            const value = payload[inputName]

            if (inputName === 'booking_code') {
                const isTicketNumber = /^\d+$/.test(value)

                if (isTicketNumber) {
                    data['request.EticketNumber'] = value
                } else {
                    data['request.BookingCode'] = value
                }
            } else if (inputName === 'location_from') {
                // Airport code
                const { code } = value
                data['request.BoardingPoint'] = code
            }
        })

        return {
            ...this.defaults,
            ...data,
        }
    }
}

export default IPortCheckIn

