import FireBase from '../../../utils/firebase'
import Maureva from '../../../utils/maureva'

import { Offcanvas } from 'bootstrap'

class UserLogin {
    constructor(domElement = null, {
        onAuthChange = null
    }) {
        this.container = $(domElement)
        if (!this.container.length) {
            console.error('Please provide proper DOM element for UserLogin class')
            return
        }

        // Offcanvas instance
        this.drawer = new Offcanvas(this.container[0])

        // Firebase & Maureva utils init
        this.Maureva = new Maureva()

        /**
         * @type {FireBase}
         */
        this.FireBase = window.FireBase

        this.form = this.container.find('.user-login-form')
        this.submitBtn = this.container.find('.btn-submit')

        const {
            loginMessages
        } = window.errorMesages

        this.errorMesages = loginMessages

        this.onAuthChange = null
        if(onAuthChange && typeof onAuthChange === 'function') {
            this.onAuthChange = onAuthChange
        }

        this.setEventListeners()
        this.checkUserSession()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.FireBase = null
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.container.on('click', '.btn-submit', this.handleFormSubmit.bind(this))
        this.container.on('click', '.btn-social', this.handleSocialLogin.bind(this))
        this.container.on('click', '.resend-verification-email', this.resendVerificationEmail.bind(this))
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click')
    }

    /**
     * Show login drawer
     */
    showDrawer() {
        this.drawer.show()
    }

    /**
     * Hide login drawer
     */
    hideDrawer() {
        this.drawer.hide()
    }

    /**
     * Check wheter user is already logged in firebase
     */
    async checkUserSession() {
        try {
            const userUid = await this.FireBase.getLoggedUser()
            if(userUid) {
                this.fetchUserData(userUid)
            } else {
                // Check if we need to show login drawer
                this.checkOnLoadShow()

                // Clear localStorage user data
                window.localStorage.removeItem('user_data')

                // Trigger CB function so we can update header
                if(this.onAuthChange) {
                    this.onAuthChange()
                }
            }

            // Remove "login" query param
            this.clearQueryParam()
        } catch(err) {
            console.error(err)
            throw err
        }
    }

    /**
     * Check wheter we will show login drawer onload
     */
    checkOnLoadShow() {
        const url = new URL(window.location.href)
        if (url.searchParams.has('login')) {
            // Show drawer
            this.showDrawer()
        }
    }

    /**
     * Clear "login" & "return" query params
     */
    clearQueryParam() {
        if (typeof window.history.replaceState != 'undefined') {
            const url = new URL(window.location.href)

            url.searchParams.delete('login')
            url.searchParams.delete('return')
            const cleanUri = url.toString()

            window.history.replaceState(null, null, cleanUri)
        }
    }

    /**
     * Submit button handler
     * @param {Object} event
     */
    handleFormSubmit(event) {
        event.preventDefault()
        event.stopPropagation()

        // Disable multiple requests
        if (this.submitBtn.hasClass('is-requesting')) {
            return
        }

        // Validate
        const isValid = this.validateForm()
        if (isValid) {
            // Set flag
            this.submitBtn.addClass('is-requesting')

            // Create payload & submit
            const payload = this.createPayload()
            this.handleNormalLogin(payload)
        }
    }

    /**
     * Form handler
     * @param {Object} payload
     */
    async handleNormalLogin(payload = {}) {
        try {
            // Login user & fetch it's uid from Firebase
            const userUid = await this.FireBase.loginUser(payload)

            // Fetch full user data from Maureva
            await this.fetchUserData(userUid)

            // Remove loading flag
            this.submitBtn.removeClass('is-requesting')

        } catch (error) {
            this.handleFirebaseLoginError(error)
            this.submitBtn.removeClass('is-requesting')

            throw error
        }

    }

    /**
     * Fetch user data from Maureva
     * @param {String} userUid
     */
    async fetchUserData(userUid = null) {
        try {
            // Fetch user data from Maureva
            const userResponse = await this.Maureva.retrieveUserData(userUid)
            //const userResponse = window.UserAuth.getDummyData()

            // Handle Maureva response
            this.handleLoginResponse(userResponse.data)

            return

        } catch(err) {
            // Logout user from Firebase
            await this.FireBase.logOutUser()

            // Trigger CB function
            if(this.onAuthChange) {
                this.onAuthChange()
            }

            throw err
        }
    }

    /**
     * Handler for Firebase login error
     * @param {Object} error
     */
    handleFirebaseLoginError(error) {
        const { code } = error

        if (code === 'auth/user-not-found') {
            // User not found
            const $emailField = this.form.find('.form-input[data-name="email"]')
            this.setFieldError($emailField,  this.errorMesages.email_response_error)
        } else if(code === 'auth/wrong-password') {
            // Wrong password
            const $passwordField = this.form.find('.form-input[data-name="password"]')
            this.setFieldError($passwordField,  this.errorMesages.password_response_error)
        } else if(code === 'auth/email-not-verified') {
            // E-mail not verified
            this.showAlert(this.emailNotVerifiedMessage(), 'warning')
        } else {
            // Generic server error
            this.showAlert(this.errorMesages.firebase_generic_error)
        }
    }

    /**
     * Handler for Maureva response
     * @param {Object} data
     */
    handleLoginResponse(data = {}) {
        const { successfulReply } = data

        if (successfulReply) {
            // Handle user data
            const { response } = data

            // Set user data to localStorage
            window.localStorage.setItem('user_data', JSON.stringify(response))

            // Trigger CB function
            if(this.onAuthChange) {
                this.onAuthChange()
            }

            // Close drawer
            this.drawer.hide()
        } else {
            // Logout user from Firebase
            this.FireBase.logOutUser()

            // Show alert
            this.showAlert(this.errorMesages.maureva_profile_retrieve_error)

            // Show drawer
            this.drawer.show()
        }
    }

    /**
     * Handler for social button click
     * @param {Object} event
     */
    async handleSocialLogin(event) {
        try {
            event.preventDefault()
            event.stopPropagation()

            const $button = $(event.currentTarget)
            const { network } = $button.data()

            let userData

            switch (network) {
                case 'facebook':
                    userData = await this.FireBase.loginByFacebook()
                    break

                case 'google':
                    userData = await this.FireBase.loginByGoogle()
                    break
            }

            this.submitBtn.addClass('is-requesting')

            const userResponse = await this.Maureva.createUserFromSocial(userData)
            //const userResponse = window.UserAuth.getDummyData()
            this.handleLoginResponse(userResponse.data)

            this.submitBtn.removeClass('is-requesting')

        } catch (error) {
            if(error.response && error.response.status) {
                // Generic server error (Maureva/Firebase)
                this.showAlert(this.errorMesages.firebase_generic_error)
            }

            console.error(error)

            this.FireBase.logOutUser()
            this.submitBtn.removeClass('is-requesting')

            throw error
        }
    }

    /**
     * Resend verification method (for e-mail user)
     */
    async resendVerificationEmail() {
        try {
            this.submitBtn.addClass('is-requesting')

            await this.FireBase.sendVerificationEmail()

            this.showAlert(this.errorMesages.firebase_verification_mail_resend_success, 'primary')

            this.submitBtn.removeClass('is-requesting')
        } catch(error) {
            this.submitBtn.removeClass('is-requesting')

            // Already sent
            if (error && error.code === 'auth/too-many-requests') {
                this.showAlert(this.errorMesages.firebase_verification_mail_already_sent, 'warning')
                throw error
            }

            // Generic server error (Firebase)
            this.showAlert(this.errorMesages.firebase_verification_mail_resend_error)

            throw error
        }
    }

    /**
     * Create payload
     */
    createPayload() {
        const payload = {}
        const fields = this.form.find('.form-input')
        fields.each((_, item) => {
            const $field = $(item)

            const value = $field.val()
            const { name } = $field.data()

            payload[name] = value
        })

        return payload
    }

    /**
     * Form validator
     */
    validateForm() {
        let valid = true

        const fields = this.form.find('.form-input')
        fields.each((_, item) => {
            const $field = $(item)

            const value = $field.val()
            const type = $field.attr('type')
            const isRequired = $field.prop('required')

            // Empty field
            if (isRequired && !value) {
                this.setFieldError($field, this.errorMesages.required_field)

                valid = false
                return true
            }

            // Email
            if (type === 'email') {
                if(!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                    this.setFieldError($field, this.errorMesages.email_error)

                    valid = false
                    return true
                }
            }

            // Password
            if (type === 'password') {
                // @todo add this when we got pattern
            }

            // Clear errors
            this.clearFieldError($field)
        })

        return valid
    }

    /**
     * Set validation error on field
     * @param {jQuery} $field
     * @param {String} errorMessage
     */
    setFieldError($field, errorMessage = '') {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        // Set feedback message
        $feedback.text(errorMessage)

        // Set classes
        $field.addClass('is-invalid')
        $parent.addClass('is-invalid')
    }

    /**
     * Clear validation on field
     * @param {jQuery} $field
     */
    clearFieldError($field) {
        const $parent = $field.parent()
        const $feedback = $parent.next('.invalid-feedback')

        $feedback.text('')
        $field.removeClass('is-invalid')
        $parent.removeClass('is-invalid')
    }

    /**
     * Show alert before form fields
     * @param {String} message
     */
    showAlert(message = null, type = 'danger') {
        const markup = `
            <div class="alert alert-${type} small alert-dismissible mb-6 mt-n5 fade show" role="alert">
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `
        this.container.find('.user-login-form--alert-container').html(markup)
    }

    /**
     * Create markup for email not verified
     * @returns {String}
     */
    emailNotVerifiedMessage() {
        const markup = `
            ${this.errorMesages.email_not_verified_error} </br>
            <a
                href="javascript:;"
                title="Resend user verification email"
                class="resend-verification-email text-reset text-decoration-underline fw-bold"
            >
                ${this.errorMesages.email_resend_verification}
            </a>
        `

        return markup
    }
}

export default UserLogin
