import Forms from '../..'
import FlightInfo from './FlightInfo'

class ApplyForVoucher extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.flightInfoContainer = this.form.find('.flight-info-container')
        this.FlightInfo = new FlightInfo(this.flightInfoContainer[0])

        this.addEventListeners()
    }

    /**
     * Destructor
     */
    destroy() {
        this.FlightInfo.destroy()
        this.FlightInfo = null

        this.removeEventListeners()
    }

    addEventListeners() {
        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            this.FlightInfo.resetFields()
        })
    }

    removeEventListeners() {
        this.form.off('change')
    }

    afterSubmit() {
        this.FlightInfo.setOptions()
    }
}

export default ApplyForVoucher
