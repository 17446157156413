import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class SabreMYB {
    constructor(baseUrl = null) {
        this.baseUrl = baseUrl

        const {
            country,
            language,
        } = window.localeData

        this.userLanguage = language
        this.userCountry = country === 'GLOBAL' ? 'rs' : country

        this.defaultOptions = {
            locale: this.localeFormatter(this.userLanguage, this.userCountry)
        }
    }

    engage(payload = {}, newWindow = true) {
        const data = this.formatPayload(payload)
        const querries = createUrlParamsFromObject(data)

        const redirectUrl = `${this.baseUrl}?${querries}`
        if (newWindow) {
            window.open(redirectUrl, '_blank')
        } else {
            window.open(redirectUrl, '_self')
        }
    }

    formatPayload(payload = {}) {
        const data = {}
        Object.keys(payload).forEach(name => {
            const value = payload[name]

            if (name === 'booking_code') {
                data['pnr'] = value
            }

            if (name === 'surname') {
                data['lastName'] = value
            }
        })

        return {
            ...this.defaultOptions,
            ...data
        }
    }

    /**
     * Locale formatter
     */
    localeFormatter(language = null, country = null) {
        if (language === 'en' && country === 'us') {
            return 'en-US'
        } else {
            const formatter = {
                'sr_Latn': 'sr-LATN',
                'sr_Cyrl': 'sr-CYRL',
                'en': 'en-GB',
                'de': 'de-DE',
                'fr': 'fr-FR',
                'ru': 'ru-RU',
                'el': 'el-GR',
                'sv': 'sv-SE',
                'it': 'it-IT',
                'nl': 'nl-NL',
                'es': 'es-ES',
                'tr': 'tr-TR',
            }

            if (formatter.hasOwnProperty(language)) {
                return formatter[language]
            } else {
                return formatter['en']
            }
        }
    }
}

export default SabreMYB