import Forms from '../..'
import FlightInfo from './FlightInfo'

class Refund extends Forms {
    constructor(domElement = null) {
        super(domElement)

        this.flightInfoContainer = this.form.find('.flight-info-container')
        this.FlightInfo = new FlightInfo(this.flightInfoContainer[0])

        this.addEventListeners()
        this.setBankCardContainer()
    }

    /**
     * Destructor
     */
    destroy() {
        this.FlightInfo.destroy()
        this.FlightInfo = null

        this.removeEventListeners()
    }

    addEventListeners() {
        this.form.on('change', '.active-bank-account-radio-input', () => this.setBankCardContainer())
        this.form.closest('.offcanvas')[0].addEventListener('hidden.bs.offcanvas', () => {
            this.FlightInfo.resetFields()
        })
    }

    removeEventListeners() {
        this.form.off('change')
    }

    afterSubmit() {
        this.FlightInfo.setOptions()

        this.setBankCardContainer()
    }

    setBankCardContainer() {
        const selectedInput = this.form.find('.active-bank-account-radio-input:checked')
        const selectedValue = selectedInput.val()

        const bankCardContainer = this.form.find('.bank-container-fields')

        if (selectedValue === 'no') {
            bankCardContainer.addClass('d-none')
        } else {
            bankCardContainer.removeClass('d-none')
        }
    }
}

export default Refund
