/**
 * Header ticker component
 */

class HeaderTicker {

    /**
     * HeaderTicker component constructor
     * @param {HTMLElement} domElement
     */
    constructor(domElement = null) {
        this.container = $(domElement)
        this.isEditmode = this.container.hasClass('editmode')

        if (this.isEditmode) {
            return
        }

        const { name, index } = this.container.data()
        this.brickId = `${name}-brick_${index}`

        this.init()
        this.setEventListeners()
    }

    /**
     * Destructor method
     */
    destroy() {
        this.removeEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.adjustHeight()

        // Close ticker action
        this.container.on('click touch', '.ticker-close-button', () => {
            this.container.addClass('d-none')
            this.removeEventListeners()
            this.saveToLocalStorage()

            // Remove custom css
            this.container.parents('body').find('main').first().css('margin-top', '')
        })

        // Bootstrap doesn't register keyboard controls until you manually press the button at least once for some reason
        $(document).on(`keydown.${this.brickId}`, event => {
            if (event.keyCode == 37) {
                this.container.find('.carousel-control-prev').trigger('click')
            }

            if (event.keyCode == 39) {
                this.container.find('.carousel-control-next').trigger('click')
            }
        })

        $(window).on('resize', () => {
            this.adjustHeight()
        })
    }

    /**
     * Remove attached event listeners
     */
    removeEventListeners() {
        this.container.off('click touch')
        $(document).off(`keydown.${this.brickId}`)
    }

    init() {
        // Set max height as full screen
        const calculatedHeight = window.innerHeight
        this.container.css('maxHeight', `${calculatedHeight}px`)

        // Move it to top of the page
        this.container.removeClass('position-fixed top-0 start-0')
        $('header').prepend(this.container)

        this.checkLocalStorage()
    }

    checkLocalStorage() {
        // Check if notifications were already removed
        const notifications = JSON.parse(localStorage.getItem('notifications')) ?? []

        const carouselItems = this.container.find('.carousel-item')
        carouselItems.each((_, item) => {
            const elements = $(item).find('.main-text-wrapper').children()
            elements.each((index, element) => {
                if (index == 0) {
                    $(element).addClass('first-element')
                } else {
                    $(element).addClass('d-none')
                }
            })

            // Handle any width icon
            const textContainer = $(item).find('.text-container-content')
            if (textContainer.hasClass('has-icon')) {
                const iconClone = textContainer.parent().find('.image-container').clone()
                // iconClone.css('display', 'block')
                iconClone.css('visibility','hidden')
                $('body').append(iconClone);
                const icon = iconClone.find('img, svg').eq(0).css({'height': '24px', 'width': 'auto'})
                const iconWidth = icon.outerWidth() + 10 // 10 is gap between icon and text
                iconClone.remove()
                textContainer.css('max-width', `calc( 100% - ${iconWidth}px )`)
            }
        })

        if (!notifications || !notifications.length) {
            this.container.removeClass('d-none')
            return
        }

        const newNotifications = []
        carouselItems.each((_, item) => {
            const { id } = $(item).data()
            const notification = item.innerHTML
            const foundNotification = notifications.find(el => el.id == id)
            if (!foundNotification || foundNotification.content != notification) {
                newNotifications.push(id)
            }
        })

        if (newNotifications.length) {
            this.container.removeClass('d-none')
            let setActive = false

            carouselItems.each((_, item) => {
                const { id } = $(item).data()

                if (!newNotifications.includes(id)) {
                    $(item).remove()
                } else {
                    // Set first notification that is new as active slide
                    if (!setActive) {
                        $(item).addClass('active')
                        setActive = true
                    }
                }
            })
        }
    }

    saveToLocalStorage() {
        const carouselItems = this.container.find('.carousel-item')
        const notifications = JSON.parse(localStorage.getItem('notifications')) ?? []
        carouselItems.each((_, item) => {
            const { id } = $(item).data()
            const notification = item.innerHTML
            notifications.push({
                id: id,
                content: notification
            })
        })
        localStorage.setItem('notifications', JSON.stringify(notifications))
    }

    adjustHeight() {
        // Add custom css
        const header = this.container.parent('header')
        this.container.parents('body').find('main').first().css('margin-top', `${header.outerHeight()}px`)
    }
}

export default HeaderTicker
