import axios from 'axios'
import Pagination from 'ContentBundle/js/frontend/partials/Pagination'

import { createUrlParamsFromObject } from 'ContentBundle/js/frontend/utils/qS'

class EmergencyPage {
    constructor(domElement = null) {
        this.container = $(domElement)

        if(!this.container.length) {
            console.error('Please provide proper element for EmergencyPage class')
            return
        }

        this.sidebarForm = this.container.find('.sidebar-form form')

        this.listContainer = this.container.find('.announcements-list--items')
        this.Pagination = null

        this.init()
    }

    init() {
        this.initPagination()
        this.setEventListeners()
    }

    /**
     * Attach event listeners
     */
    setEventListeners() {
        this.sidebarForm.on('blur', '.email-input', event => {
            this.setSubmitButtonState(true)
        })

        this.sidebarForm.on('change', '.privacy-policy', event => {
            this.setSubmitButtonState(true)
        })

        this.sidebarForm.on('submit', async event => {
            event.preventDefault()

            const $submitButton = this.sidebarForm.find('button[type="submit"]')
            const action = this.sidebarForm.attr('action')
            const email = this.sidebarForm.find('.email-input').val()
            const formData = new FormData()
            formData.append('email', email)

            $submitButton.addClass('loading')
            
            try {
                await axios.post(action, formData)
                $submitButton.removeClass('loading')
                this.clearForm()
            } catch(err) {
                $submitButton.removeClass('loading')
                console.error('Error sending data')
                throw err
            }
        })
    }

    /**
     * (Re)Initialize pagination component
     */
    initPagination() {
        // Remove pagination
        if (this.Pagination) {
            this.Pagination.destroy()
            this.Pagination = null
        }

        // Re-init
        this.paginationContainer = this.container.find('.announcements-list--pagination .pagination-container')
        if (this.paginationContainer.length) {
            this.Pagination = new Pagination(this.paginationContainer[0], {
                onPageChange: page => this.handlePaginationChange(page)
            })
        }
    }

    /**
     * Set requesting class
     */
    setRequestingFlag() {
        this.listContainer.addClass('is-requesting')
        this.listContainer.find('.loader-container').removeClass('d-none')
    }

    /**
     * Remove requesting flag
     */
    removeRequestingFlag() {
        this.listContainer.removeClass('is-requesting')
        this.listContainer.find('.loader-container').addClass('d-none')
    }

    /**
     * Pagination page change
     * @param {Number} page
     */
    handlePaginationChange(page = 1) {
        // Scroll to top
        $([document.documentElement, document.body]).animate({
            scrollTop: this.container.offset().top - $('header.header').height()
        }, 400)

        // Fetch new list data
        this.fetchListData(page)
    }

    /**
     * Make request for new list data
     * @param {String} queryParams
     * @returns {Promise}
     */
    async fetchListData(page = 1) {
        try {
            this.setRequestingFlag()

            const requestUri = this.createRequestUri({ page })

            const response = await axios.get(requestUri)
            const { data } = response

            this.setListData(data)

            this.removeRequestingFlag()

            return
        } catch(err) {
            this.removeRequestingFlag()
            console.error(err)
            throw err
        }
    }

    /**
     * Update list content
     * @param {String} responseData
     */
    setListData(responseData = '') {
        // Re-init Pagination
        const $responseMarkup = $('<div />').append(responseData)

        const $listResponse = $responseMarkup.find('.announcements-list--items')
        const listMarkup = $listResponse.html()

        this.listContainer.html(listMarkup)
        this.initPagination()
    }

    /**
     * Create proper requestUri
     * @param {page} Number
     * @returns {String}
     */
    createRequestUri({ page = 1 }) {
        const queryParams = createUrlParamsFromObject({
            page
        })

        let requestUri = window.location.href.split(/[?#]/)[0]
        requestUri = `${requestUri}?${queryParams}`

        // Update browser url
        if (typeof window.history.pushState != 'undefined') {
            window.history.pushState(null, null, requestUri)
        }

        return requestUri
    }

    /**
     * Set submit button state
     */
    setSubmitButtonState(showError = false) {
        const $submitButton = this.sidebarForm.find('button[type="submit"]')
        const $emailField = this.sidebarForm.find('.email-input')
        const $privacyPolicyField = this.sidebarForm.find('.privacy-policy')

        const validEmail = this.validateInput($emailField, showError)
        const privacyChecked = $privacyPolicyField[0].checked

        const isValid = validEmail && privacyChecked

        if(!isValid) {
            $submitButton.addClass('disabled')
        } else {
            $submitButton.removeClass('disabled')
        }
    }

    /**
     * Single input validation
     * @param {Jquery} $input
     */
    validateInput($input, showError = true) {
        if (!this.validateNotBlank($input, showError)) {
            return false
        }

        if (!this.validateMailStructure($input, showError)) {
            return false
        }

        return true
    }

    /**
     * Check if field is empty
     * @param {JQuery} $input
     * @returns {Boolean}
     */
    validateNotBlank($input, showError = true) {
        const $inputContainer = $input.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        $input.removeClass('is-invalid')
        $inputContainer.removeClass('is-invalid')

        $inputErrorMsg.addClass('d-none')
        $inputErrorMsg.html('')

        if (!$input.val().length && showError) {
            $input.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            console.log(msg)
            $inputErrorMsg.removeClass('d-none')
            $inputErrorMsg.html(msg.empty)

            return false
        }

        return true
    }

    /**
     * Check for e-mail structure
     * @param {Juqery} $input
     * @returns {Boolean}
     */
    validateMailStructure($input, showError = true) {
        const $inputContainer = $input.closest('.form-floating')
        const $inputErrorMsg = $inputContainer.next('.invalid-feedback-js')

        $input.removeClass('is-invalid')
        $inputContainer.removeClass('is-invalid')

        $inputErrorMsg.addClass('d-none')
        $inputErrorMsg.html('')

        // 99.99% accuracy => RFC 5322 Format
        const regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

        if (!regex.test($input.val()) && showError) {
            $input.addClass('is-invalid')
            $inputContainer.addClass('is-invalid')

            const { msg } = $inputErrorMsg.data()
            $inputErrorMsg.removeClass('d-none')
            $inputErrorMsg.html(msg.invalid)

            return false
        }

        return true
    }

    clearForm() {
        this.sidebarForm.find('.email-input').val('')
        this.sidebarForm.find('.privacy-policy').prop('checked', false)
        this.sidebarForm.find('button[type="submit"]').blur().addClass('disabled')
    }
}

export default EmergencyPage
