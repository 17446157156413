import moment from 'moment'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import {
    getAuth,
	signOut,
    deleteUser,
    updateEmail,
    updatePassword,
    signInWithPopup,
    sendEmailVerification,
    fetchSignInMethodsForEmail,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    reauthenticateWithCredential,
    onAuthStateChanged,
    EmailAuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    getAdditionalUserInfo,
} from 'firebase/auth'

class FireBase {
    constructor() {
        this.app = null
        this.messaging = null
        this.firebaseConfig = null

        this.init()
    }

    init() {
        this.setConfig()

        this.app = initializeApp(this.firebaseConfig)
        this.analytics = getAnalytics(this.app)
    }

	/**
	 * Check user auth state
     * @returns {Promise}
	 */
	getLoggedUser() {
		return new Promise((resolve, reject) => {
			const auth = getAuth()
			onAuthStateChanged(auth, user => {
				const userResponse = user ? user.uid : null

                if (user && !user.emailVerified) {
                    resolve(null)
                }

				resolve(userResponse)
			})
		})
	}

    /**
     * Retrieve firebase access token
     * @returns {Promise}
     */
    getAccessToken() {
        return new Promise((resolve, reject) => {
			const auth = getAuth()
			onAuthStateChanged(auth, user => {
				const token = user ? user.accessToken : null

				resolve(token)
			})
		})
    }

    /**
     * Get current user providers
     * @returns {Promise}
     */
    getUserProviders() {
        return new Promise((resolve, reject) => {
			const auth = getAuth()
			onAuthStateChanged(auth, user => {
                const providers = []
                if (user) {
                    user.providerData.forEach(provider => {
                        const { providerId } = provider
                        providers.push(providerId)
                    })
                }

				resolve(providers)
			})
		})
    }

	/**
	 * Logout action
	 */
	async logOutUser() {
		try {
			const auth = getAuth()
			await signOut(auth)

            // Remove localStorage user data
            window.localStorage.removeItem('user_data')

			return
		} catch(error) {
			console.log(error.code)
            console.log(error.message)

			throw error
		}
	}

    /**
	 * Login user via provided email and password
	 * @returns {String} userUid
	 */
    async loginUser({ email = '', password = '' }) {
        try {
            const auth = getAuth()
            const response = await signInWithEmailAndPassword(auth, email, password)

            if (!response.user.emailVerified) {
                throw {
                    code: 'auth/email-not-verified'
                }
            }

			return response.user.uid
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Register user by provided email and password
     * @returns {String} userUid
     */
    async registerUser({ email = '', password = '' }) {
        try {
            const auth = getAuth()
            const response = await createUserWithEmailAndPassword(auth, email, password)

			return response.user.uid
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Reauthenticate user by email & password
     * @param {String} email
     * @param {String} password
     * @returns {Promise}
     */
    async reauthenticateUserByEmailAndPassword({ email = '', password = '' }) {
        try {
            const auth = getAuth()
            const credential = EmailAuthProvider.credential(
                email,
                password
            )

            const response = await reauthenticateWithCredential(auth.currentUser, credential)

            return response
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Delete user
     */
    async deleteUser() {
        try {
            const auth = getAuth()
            const response = await deleteUser(auth.currentUser)

            return response
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Facebook social login
     */
    async loginByFacebook() {
        // https://stackoverflow.com/questions/65306782/facebook-firebase-auth-and-facebook-graph-api-with-react
        try {
            const auth = getAuth()
            const provider = new FacebookAuthProvider()

            const loginResponse = await signInWithPopup(auth, provider)
            const extraUserData = await getAdditionalUserInfo(loginResponse)

            //const userToken =  loginResponse.credential.accessToken
            //onsole.log(loginResponse, extraUserData, userToken);

            const data = this.formatFacebookUserData(loginResponse, extraUserData)

			return data

        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Google Social login
     */
    async loginByGoogle() {
        try {
            const auth = getAuth()
            const provider = new GoogleAuthProvider()

			provider.addScope('openid')
			provider.addScope('profile')
            provider.addScope('https://www.googleapis.com/auth/user.gender.read')
            provider.addScope('https://www.googleapis.com/auth/user.birthday.read')
			provider.addScope('https://www.googleapis.com/auth/userinfo.profile')

            const loginResponse = await signInWithPopup(auth, provider)
            const extraUserData = await getAdditionalUserInfo(loginResponse)

            /*
            const credential = GoogleAuthProvider.credentialFromResult(loginResponse)
            const userToken = credential.accessToken

            await axios.get(`https://people.googleapis.com/v1/people/me?personFields=genders,birthdays`, {
                headers: {
                    "Authorization" : `Bearer ${userToken}`,
                    "Accept": "application/json",
                }
            })
            */

            const data = this.formatGoogleUserData(loginResponse, extraUserData)

			return data

        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Check if provided e-mail is already in use
     * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#fetchsigninmethodsforemail
     * @param {String} email
     * @returns {Boolean} availability (true if available)
     */
    async checkEmailAvailability(email = '') {
        try {
            const auth = getAuth()
            const response = await fetchSignInMethodsForEmail(auth, email)

            return !response.length
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Send verification e-mail if user registered via e-mail & password
     * @returns {void}
     */
    async sendVerificationEmail() {
        try {
            const auth = getAuth()
            await sendEmailVerification(auth.currentUser, {
                url: window.location.origin
            })

            return
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Update user email
     * @param {String} email
     * @returns {Promise}
     */
    async updateUserEmail(email = '') {
        try {
            const auth = getAuth()
            const response = await updateEmail(auth.currentUser, email)

            return response
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Update user password
     * @param {String} password
     * @returns {Promise}
     */
    async updateUserPassword(password = '') {
        try {
            const auth = getAuth()
            const response = await updatePassword(auth.currentUser, password)

            return response
        } catch(error) {
            console.log(error.code)
            console.log(error.message)

			throw error
        }
    }

    /**
     * Format Google Social login response to proper user data
     * @param {Object} responseData
     */
    formatFacebookUserData(responseData = {}, extraUserData = {}) {
		const userData = responseData.user
		const tokenResponse = responseData._tokenResponse

		return {
			login: userData.email,
			firstname: tokenResponse.firstName,
			lastname: tokenResponse.lastName,
			displayName: userData.displayName,
			gender: 'M',
            createdAt: moment().toISOString(),
			email: userData.email,
			userUuid: userData.uid,
			photoUrl: userData.photoURL,
		}
    }

    /**
     * Format Google Social login response to proper user data
     * @param {Object} responseData
     */
    formatGoogleUserData(responseData = {}, extraUserData = {}) {
		const userData = responseData.user
		const tokenResponse = responseData._tokenResponse
        const { locale } = extraUserData.profile

		return {
			login: userData.email,
			firstname: tokenResponse.firstName,
			lastname: tokenResponse.lastName,
			displayName: userData.displayName,
			gender: 'M',
			createdAt: moment().toISOString(), // Use current time,
			email: userData.email,
			userUuid: userData.uid,
			photoUrl: userData.photoURL,
		}
    }

	/**
	 * Format Timestamp to Maureva's API format
	 * @param {String} timestamp
	 */
	formatDateByTimestamp(timestamp = null) {
		return timestamp ? moment.unix(timestamp).format() : null
	}

    setConfig() {
        if(!window.firebaseConfig) {
            console.error('Missing Firebase config in environment')
        }

        this.firebaseConfig = window.firebaseConfig

        /*
        this.firebaseConfig = {
            apiKey: "AIzaSyCL5I5yYu9XzsJ5FJqcO-OZbBJrTINAcqc",
            authDomain: "test-ionic-app-82681.firebaseapp.com",
            databaseURL: "https://test-ionic-app-82681.firebaseio.com",
            projectId: "test-ionic-app-82681",
            storageBucket: "test-ionic-app-82681.appspot.com",
            messagingSenderId: "950733025144",
            appId: "1:950733025144:web:3e7f1226f9ae22cf0b228d",
            measurementId: "G-X2BNP610X2"
        }
        */
    }
}

export default FireBase
